/* eslint-disable no-restricted-imports */
import { ADMINISTRATION_INVESTOR_API, APPLICATION_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import {
  APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE,
  ApplicationLetterType,
} from 'src/constants/applications';
import { portalApi } from 'src/helpers/axios';
import { detectPortalType } from 'src/helpers/common';
import { IDocument } from 'src/modules/common/type';
import { IUserBody } from '../users-management/type';
import {
  ApplicationOfferQueryBody,
  ApplicationQueryBody,
  ELetterType,
  IAcceptanceSignatory,
  IAmountReceived,
  IApplicationAddtional,
  IApplicationDetails,
  IApplicationFinancialDetail,
  IApplicationQuestionnaireResponse,
  ICompleteConnectId,
  IFinaliseDetail,
  IFinancialTaxDetailsForm,
  IFundKYCAMLForm,
  IInvestmentEntityForm,
  IInvestmentEntityTypeInfo,
  ISkycCreateAppBody,
  ISubmitApplicationQuestionnaireBody,
  ITeam,
  ITeamContact,
  SearchSkycQueryBody,
  SearchSuperFundQueryBody,
} from './type';

export const getApplicationList = async (data: ApplicationQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: APPLICATION_API.getApplicationList.api,
  });

  return result.data;
};

export const archiveApplication = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.archiveApplication.api(id),
  });

  return result.data;
};

export const getApplicationOfferList = async (data: ApplicationOfferQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      ListType: data?.ListType,
    },
    url: APPLICATION_API.getApplicationOfferList.api,
  });

  return result.data;
};

export const getApplicationFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getApplicationFilter.api,
  });

  return result.data;
};

export const getApplicationUnitClassFilter = async (offerIds: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: {
      OfferIds: offerIds,
    },
    url: APPLICATION_API.getApplicationFilter.api,
  });

  return result.data;
};

export const getApplicationInvestorTypes = async (unitClassId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getApplicationInvestorTypes.api(unitClassId),
  });

  return result.data;
};

export const getInvestorOfferDetailsPreview = async (offerId: string, unitClassId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getInvestorOfferDetailsPreview.api(offerId, unitClassId),
  });

  return result.data;
};

export const upsertInvestmentEntity = async (data: IInvestmentEntityForm, id?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: APPLICATION_API.upsertInvestmentEntity.api(id),
  });
  return result.data;
};

export const upsertApplicationDetails = async (data: IApplicationDetails, id?: string) => {
  const result = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    data,
    url: APPLICATION_API.upsertApplicationDetails.api(id),
  });
  return result.data;
};

export const getInvestorOfferInfo = async (offerId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getInvestorOfferInfo.api(offerId),
  });

  return result.data;
};

export const getInvestorOfferInfoByOfferCode = async (offerCode: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getInvestorOfferInfoByOfferCode.api(offerCode),
  });

  return result.data;
};

export const downloadFundDocument = async (documentId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      documentId,
    },
    url: APPLICATION_API.downloadFundDocument.api,
    responseType: 'arraybuffer',
  });
  return result.data;
};

export const updateFinancialDetail = async (id: string, data: IApplicationFinancialDetail) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.updateFinancialDetail.api(id),
    data,
  });
  return result.data;
};

export const getApplicationOfferUnitClassDetail = async (offerId: string, unitClassId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      unitClassId,
    },
    url: APPLICATION_API.getApplicationOfferUnitClassDetail.api(offerId),
  });

  return result.data;
};

export const getApplicationById = async (
  id: string,
  params: { step: number; financialStep?: number },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: APPLICATION_API.getApplicationById.api(id),
  });

  return result.data;
};

export const upsertApplicationAdditional = async (id: string, data: IApplicationAddtional) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.upsertApplicationAdditional.api(id),
    data,
  });
  return result.data;
};

export const getApplicationEntityTypes = async (id?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getApplicationEntityTypes.api(id),
  });

  return result.data;
};

export const upsertTeamContact = async (applicationId: string, data: ITeamContact) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: APPLICATION_API.upsertTeamContact.api(applicationId),
  });

  return result.data;
};

export const getTeamContact = async (applicationId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getTeamContact.api(applicationId),
  });

  return result.data;
};

export const upsertTeam = async (applicationId: string, data: ITeam) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: APPLICATION_API.upsertTeam.api(applicationId),
  });

  return result.data;
};

export const deleteTeamContact = async (contactId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: APPLICATION_API.deleteTeamContact.api(contactId),
  });

  return result.data;
};

export const getWholesaleCertificate = async (applicationId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    responseType: 'arraybuffer',
    url: APPLICATION_API.getWholesaleCertificate.api(applicationId),
  });

  return {
    data: result?.data,
    fileSize: Number(result.headers['content-length']),
  };
};

export const deleteAdditionalDocument = async ({
  applicationId,
  data,
}: {
  applicationId: string;
  data: { documentId: string };
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    data,
    url: APPLICATION_API.deleteAdditionalDocument.api(applicationId),
  });

  return result.data;
};

export const downloadAdditionalDocument = async ({
  applicationId,
  data,
}: {
  applicationId: string;
  data: { documentId: string; type: number };
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: data,
    url: APPLICATION_API.downloadAdditionalDocument.api(applicationId),
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const searchSkyc = async (data: SearchSkycQueryBody) => {
  const { isFunds } = detectPortalType();
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: isFunds ? ADMINISTRATION_INVESTOR_API.searchSkyc.api : APPLICATION_API.searchSkyc.api,
  });
  return result.data;
};

export const searchSuperFund = async (data: SearchSuperFundQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: APPLICATION_API.searchSuperFund.api,
  });
  return result.data;
};

export const getSignatoryContact = async (applicationId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getSignatoryContact.api(applicationId),
  });

  return result.data;
};

export const upsertApplicationAcceptance = async (
  id: string,
  data: { signatories: IAcceptanceSignatory[] },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: APPLICATION_API.upsertApplicationAcceptance.api(id),
  });

  return result.data;
};

export const downloadDeclarationDocument = async (
  applicationId?: string,
  typeLetter?: ApplicationLetterType,
  contactId?: string,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: { typeLetter, contactId },
    url: APPLICATION_API.downloadDeclarationDocument.api(applicationId),
    responseType: 'arraybuffer',
  });
  return result.data;
};

export const checkDuplicateAbnAcn = async (
  params: IInvestmentEntityTypeInfo & {
    abn: string;
    acn: string;
  },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: APPLICATION_API.checkDuplicateAbnAcn.api,
  });
  return result.data;
};

export const applicationSkycCreateApp = async (data: ISkycCreateAppBody) => {
  const { isFunds } = detectPortalType();
  const { id, ...restData } = data;
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: restData,
    url: isFunds
      ? APPLICATION_API.skycCreateApp.api
      : APPLICATION_API.applicationSkycCreateApp.api(id),
  });

  return result.data;
};

export const removeApplicationOffer = async (id?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.removeApplicationOffer.api(id),
  });
  return result.data;
};
export const registerDocumentIdKYC = async (data: {
  appId: string;
  individualId: string;
  code?: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.registerDocumentIdKYC.api(data.appId, data.individualId, data.code),
    baseURL: process.env.REACT_APP_INVESTOR_PORTAL_API_URL,
  });
  return result.data;
};

export const applicationSkycGetApp = async (id: string) => {
  const { isFunds } = detectPortalType();

  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: isFunds
      ? APPLICATION_API.skycGetApp.api(id)
      : APPLICATION_API.applicationSkycGetApp.api(id),
  });
  return result.data;
};

export const rejectApplicationOffer = async (id?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.rejectApplicationOffer.api(id),
  });
  return result.data;
};
export const registerBiometricKYC = async (data: {
  appId: string;
  individualId: string;
  code?: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.registerBiometricKYC.api(data.appId, data.individualId, data.code),
    baseURL: process.env.REACT_APP_INVESTOR_PORTAL_API_URL,
  });
  return result.data;
};

export const registerConnectIdKYC = async (data: {
  appId: string;
  individualId: string;
  code?: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.registerConnectIdKYC.api(data.appId, data.individualId, data.code),
  });
  return result.data;
};

export const connectIdSelectBank = async (data: {
  authorizationServerId: string;
  code?: string;
  essentialClaims: string[];
  voluntaryClaims?: string[];
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.connectIdSelectBank.api(data.code),
    data: {
      authorisationServerId: data.authorizationServerId,
      essentialClaims: data.essentialClaims || [],
      voluntaryClaims: data.voluntaryClaims || [],
    },
  });
  return result.data;
};

export const connectIdRetrieveToken = async (data: {
  code: string;
  queryState: string;
  iss: string;
  auth_server_id: string;
  state: string;
  nonce: string;
  code_verifier: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.connectIdRetrieveToken.api(),
    data,
  });
  return result.data;
};

export const favouriteApplicationOffer = async (id?: string, isFavourite?: boolean) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: { isFavourite: !isFavourite },
    url: APPLICATION_API.favouriteApplicationOffer.api(id),
  });
  return result.data;
};

export const requestVerifyKYC = async (data: {
  appId: string;
  individualId: string;
  email: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: { email: data.email },
    url: APPLICATION_API.requestVerifyKYC.api(data.appId, data.individualId),
  });
  return result.data;
};

export const completedDocumentIdKYC = async (data: {
  appId: string;
  individualId: string;
  code?: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.completedDocumentIdKYC.api(data.appId, data.individualId, data.code),
    baseURL: process.env.REACT_APP_INVESTOR_PORTAL_API_URL,
  });
  return result.data;
};

export const completedBiometricKYC = async (data: {
  appId: string;
  individualId: string;
  code?: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.completedBiometricKYC.api(data.appId, data.individualId, data.code),
    baseURL: process.env.REACT_APP_INVESTOR_PORTAL_API_URL,
  });
  return result.data;
};

export const completedConnectIdKYC = async (data: ICompleteConnectId) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.completedConnectIdKYC.api(data.appId, data.individualId, data.code),
    data: data.body,
  });
  return result.data;
};

export const getKYCInfo = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getKYCInfo.api(id),
  });

  return result.data;
};

export const submitApplication = async (id?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.submitApplication.api(id),
  });
  return result.data;
};

export const getInvestmentEntitiesByEntityType = async (entityType: number, keyword?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      keyword,
    },
    url: APPLICATION_API.getInvestmentEntitiesByEntityType.api(entityType),
  });

  return result.data;
};

export const getInvestmentEntityDetail = async (entityId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getInvestmentEntityDetail.api(entityId),
  });

  return result.data;
};

export const getInvestmentEntityUserInfo = async (entityId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getInvestmentEntityUserInfo.api(entityId),
  });

  return result.data;
};

export const getInvestmentEntityDocuments = async (entityId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getInvestmentEntityDocuments.api(entityId),
  });

  return result.data;
};

export const getTaxDetails = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getTaxDetails.api(appId),
  });

  return result.data;
};

export const upsertFinancialAndTaxDetails = async (id: string, data: IFinancialTaxDetailsForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: APPLICATION_API.upsertFinancialAndTaxDetails.api(id),
  });

  return result.data;
};

export const getApplicationOverview = async (appId: string, step: number) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: APPLICATION_API.getApplicationOverview.api(appId),
    data: { step },
  });

  return result.data;
};

export const confirmInvestmentAmount = async (
  appId: string,
  data: { investmentAmount: number; capitalCallAmount?: number | null },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.confirmInvestmentAmount.api(appId),
    data,
  });

  return result.data;
};

export const updateInvestmentAmountReceived = async (
  appId: string,
  investmentAmount: IAmountReceived[],
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.updateInvestmentAmountReceived.api(appId),
    data: investmentAmount,
  });

  return result.data;
};

export const approveApplication = async (appId: string, step: number) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.approveApplication.api(appId),
    data: { step },
  });

  return result.data;
};

export const rejectApplication = async (
  appId: string,
  data: { note: string; rejectedType: number },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.rejectApplication.api(appId),
    data,
  });

  return result.data;
};

export const initiateVerificationApplication = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.initiateVerificationApplication.api(appId),
  });

  return result.data;
};

export const uploadApplicationReviewDocument = async (appId: string, data: IDocument) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.uploadApplicationReviewDocument.api(appId),
    data,
  });

  return result.data;
};

export const deleteApplicationReviewDocument = async (appId: string, documentId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: APPLICATION_API.deleteApplicationReviewDocument.api(appId),
    data: { documentId },
  });

  return result.data;
};

export const downloadOverviewDocument = async (appId: string, documentId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      documentId,
    },
    url: APPLICATION_API.downloadOverviewDocument.api(appId),
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const revertInvestmentAmountReceived = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.revertInvestmentAmoutnReceived.api(appId),
  });

  return result.data;
};

export const finaliseApplication = async (appId: string, data: IFinaliseDetail) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.finaliseApplication.api(appId),
    data,
  });

  return result.data;
};

export const saveApplicationSignatories = async (
  appId: string,
  data: { signatories: IAcceptanceSignatory[]; removeContactIds: string[] },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.saveApplicationSignatories.api(appId),
    data,
  });

  return result.data;
};

export const markSignedApplicationSignatory = async (
  appId: string,
  contactId: string,
  receivedType: APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE,
  signedDate: string,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.markSignedApplicationSignatory.api(appId),
    data: { contactId, receivedType, signedDate },
  });

  return result.data;
};

export const getReceivedSignatoryTypes = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getReceivedSignatoryTypes.api(appId),
  });

  return result.data;
};

export const cancelApplication = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.cancelApplication.api(appId),
  });
  return result.data;
};

export const withdrawApplication = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.withdrawApplication.api(appId),
  });
  return result.data;
};

export const getLetterFile = async (data: { appId: string; type: ELetterType }) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    responseType: 'blob',
    data: {
      type: data.type,
    },
    url: APPLICATION_API.getLetterFile.api(data.appId),
  });

  return result.data;
};

export const getDuplicatedEntity = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getDuplicatedEntity.api(appId),
  });

  return result.data;
};

export const acceptDuplicatedEntity = async (appId: string, data: IUserBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.acceptDuplicatedEntity.api(appId),
    data,
  });

  return result.data;
};

export const rejectDuplicatedEntity = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.rejectDuplicatedEntity.api(appId),
  });

  return result.data;
};

export const overrideApplicationTeam = async (appId: string, data: { investorId: string }) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: APPLICATION_API.overrideApplicationTeam.api(appId),
    data,
  });

  return result.data;
};

export const upsertFundApplicationKYC = async (id: string, data: IFundKYCAMLForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: APPLICATION_API.upsertFundApplicationKYC.api(id),
  });

  return result.data;
};

export const deleteApplication = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: APPLICATION_API.deleteApplication.api(id),
  });

  return result.data;
};

export const getApplicationQuestionnaire = async (
  offerId: string,
): Promise<IApplicationQuestionnaireResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: APPLICATION_API.getApplicationQuestionnaire.api(offerId),
  });

  return result.data;
};

export const submitApplicationQuestionnaire = async (data: ISubmitApplicationQuestionnaireBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: APPLICATION_API.submitApplicationQuestionnaire.api,
  });

  return result.data;
};

export const downloadApplicationQuestionnaireDocument = async (data: {
  offerId: string;
  offerDocumentType: number;
  unitClassId: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      offerDocumentType: data.offerDocumentType,
      unitClassId: data.unitClassId,
    },
    url: APPLICATION_API.downloadOfferDocumentForApplicationQuestionnaire.api(data.offerId),
    responseType: 'arraybuffer',
  });

  // Access the Content-Disposition header
  const contentDisposition = result.headers['content-disposition'];
  // Extract the filename if it exists
  const filename = contentDisposition
    ? contentDisposition?.split('; ')[1]?.replace('filename=', '')?.replace(/"/g, '')
    : 'document.zip';

  return {
    data: result.data,
    filename,
  };
};
