/* eslint-disable no-restricted-imports */
import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { CheckCircleFillIcon } from 'src/assets/icons/CheckCircleFillIcon';
import { CrossCircleFillIcon } from 'src/assets/icons/CrossCircleFillIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import {
  APPLICATION_APPROVE_STEP,
  APPLICATION_SIGNATORY_STATUS,
  FUNDRAISING_OVERVIEW_STEP,
  RejectApplicationType,
  applicationStatuses,
} from 'src/constants/applications';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { UnitClassOfferType } from 'src/constants/unit-class';
import {
  useApproveApplication,
  useDeleteApplicationReviewDocument,
  useGetApplicationById,
  useGetApplicationOfferUnitClassDetail,
  useGetApplicationOverview,
  useInitiateVerificationApplication,
  useSaveApplicationSignatories,
  useUploadApplicationReviewDocument,
  useUpsertApplicationAcceptance,
} from 'src/modules/applications/hooks';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { IDocument } from 'src/modules/common/type';
import { useGetFundById } from 'src/modules/funds-management/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { UPSERT_FUND_STEPS } from '../../../funds-management/funds/components/UpsertFundsForm';
import { InvestmentApplicationForm } from '../../components';
import { INVESTMENT_APPLICATION_STEP } from '../../components/InvestmentApplicationForm';
import RejectApplicationAction from '../../components/RejectApplicationAction';

interface IApplicationReview extends IUpsertInvestmentApplicationForm {
  statusName: string;
  setReviewStep: Dispatch<SetStateAction<number | undefined>>;
  onClose: () => void;
}

const ApplicationReview = (props: IApplicationReview) => {
  const {
    id: appId = '',
    offerId = '',
    unitClassId = '',
    isViewMode,
    setReviewStep,
    statusName,
    onClose: onCloseApplicationModal,
    hasQuestionnaire,
  } = props;

  const alertModalRef = useRef<IBasicModalElement>(null);
  const confirmModalRef = useRef<IBasicModalElement>(null);

  const [currentStep, setCurrentStep] = useState(
    hasQuestionnaire
      ? INVESTMENT_APPLICATION_STEP.QUESTIONNAIRE
      : INVESTMENT_APPLICATION_STEP.DETAILS,
  );
  const [approvedStep, setApprovedStep] = useState<APPLICATION_APPROVE_STEP>();
  const [additionalDocument, setAdditionalDocument] = useState<IDocument>();
  const [applicationReviewAcceptance, setApplicationReviewAcceptance] = useState({
    enabled: false,
    disabled: true,
    removeList: [],
    addNewList: [],
    currentSelectedList: [],
    selectedList: [],
    trigger: undefined as UseFormTrigger<any> | undefined,
  });
  const [alertDescription, setAlertDescription] = useState('');

  const { data: applicationData } = useGetApplicationById(appId, {
    step: INVESTMENT_APPLICATION_STEP.DETAILS,
  });
  const { detail: applicationDetail } = applicationData || {};
  const { data: { summary, fundId = '', investmentAmount = 0 } = {} } = useGetApplicationOverview(
    appId,
    FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
  );
  const { data: { detail: fundDetail } = {} } = useGetFundById(
    fundId,
    UPSERT_FUND_STEPS.FUND_DETAILS,
  );

  const { mutate: approveApplication, isLoading: isApprovingApplication } = useApproveApplication();
  const {
    mutate: initiateVerificationApplication,
    isLoading: isInitiatingVerificationApplication,
  } = useInitiateVerificationApplication();
  const { mutateAsync: uploadDocument, isLoading: isUploadingDocument } =
    useUploadApplicationReviewDocument();
  const { mutate: deleteDocument, isLoading: isDeletingDocument } =
    useDeleteApplicationReviewDocument();
  const { mutate: saveApplicationSignatories, isLoading: isSavingSignatories } =
    useSaveApplicationSignatories();
  const {
    mutateAsync: upsertApplicationAcceptance,
    isLoading: upsertApplicationAcceptanceLoading,
  } = useUpsertApplicationAcceptance();
  const { data: applicationOfferUnitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    offerId,
    unitClassId,
  );
  const {
    minimumInvestmentAmount = 0,
    acceptInvestmentAmountUnderMinimum,
    hardFloor = 0,
    offerType,
  } = applicationOfferUnitClassDetailData || {};

  const isLoading =
    isApprovingApplication ||
    isInitiatingVerificationApplication ||
    isUploadingDocument ||
    isDeletingDocument ||
    isSavingSignatories;

  const getChangedStep = (value: number) => {
    setCurrentStep(value);
    setReviewStep(value);
  };

  const handleApproveApplication = (step: APPLICATION_APPROVE_STEP) => {
    setApprovedStep(step);
    approveApplication(
      { id: appId, step },
      {
        onSuccess: () => {
          switch (step) {
            case APPLICATION_APPROVE_STEP.InvestorTypeApproved:
              setAlertDescription('Investor type is approved.');
              break;
            case APPLICATION_APPROVE_STEP.InvestmentAmountApproved:
              setAlertDescription('Investor amount is approved.');
              break;
            default:
              break;
          }
          alertModalRef.current?.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleOpenConfirmModal = () => {
    confirmModalRef.current?.open();
  };

  const handleCloseConfirmModal = () => {
    confirmModalRef.current?.close();
  };

  const handleInitiateVerificationApplication = () => {
    handleCloseConfirmModal();
    initiateVerificationApplication(
      { id: appId },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSaveDocument = async (showAlert = true) => {
    if (!additionalDocument) return;
    await uploadDocument(
      { id: appId, data: additionalDocument },
      {
        onSuccess: () => {
          if (!showAlert) return;
          setAlertDescription('Changes have been saved successfully.');
          alertModalRef.current?.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleDeleteDocument = (id: string) => {
    deleteDocument(
      { documentId: id, appId },
      {
        onSuccess: () => setAdditionalDocument(undefined),
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSaveSignatoriesSuccess = (showAlert: boolean) => {
    if (!showAlert) return;
    setAlertDescription('Changes have been saved successfully.');
    alertModalRef.current?.open();
  };

  const handleSaveSignatories = async (showAlert = true) => {
    const isValid = applicationReviewAcceptance?.trigger
      ? await applicationReviewAcceptance.trigger()
      : true;
    const { addNewList, removeList, currentSelectedList, selectedList } =
      (applicationReviewAcceptance as any) || {};
    const isSignatoriesChanged = addNewList.length || removeList.length;
    const isSignatoriesMethodChanged =
      currentSelectedList?.[0]?.method !== selectedList?.[0]?.method;

    if (isValid && isSignatoriesChanged) {
      await saveApplicationSignatories(
        {
          id: appId,
          data: {
            signatories: applicationReviewAcceptance.addNewList,
            removeContactIds: applicationReviewAcceptance.removeList,
          },
          disableRevalidateApplication: isSignatoriesMethodChanged,
        },
        {
          onSuccess: () => {
            isSignatoriesMethodChanged
              ? upsertApplicationAcceptance(
                  {
                    id: appId,
                    data: {
                      signatories: currentSelectedList,
                    },
                  },
                  {
                    onSuccess: () => handleSaveSignatoriesSuccess(showAlert),
                    onError: handleErrorFromServer,
                  },
                )
              : () => handleSaveSignatoriesSuccess(showAlert);
          },
          onError: handleErrorFromServer,
        },
      );
    } else if (isSignatoriesMethodChanged) {
      upsertApplicationAcceptance(
        {
          id: appId,
          data: {
            signatories: currentSelectedList,
          },
        },
        {
          onSuccess: () => handleSaveSignatoriesSuccess(showAlert),
          onError: handleErrorFromServer,
        },
      );
    } else if (isValid) {
      handleSaveSignatoriesSuccess(showAlert);
    }
  };

  const handleSave = async () => {
    if (currentStep === INVESTMENT_APPLICATION_STEP.ADDITIONAL) {
      await handleSaveDocument();
    }
    if (currentStep === INVESTMENT_APPLICATION_STEP.ACCEPTANCE) {
      await handleSaveSignatories();
    }
  };

  const handleNext = async () => {
    const nextStep = currentStep + 1;
    if (!INVESTMENT_APPLICATION_STEP[nextStep]) return;
    if (
      currentStep === INVESTMENT_APPLICATION_STEP.ADDITIONAL &&
      additionalDocument &&
      !additionalDocument.id
    ) {
      await handleSaveDocument(false);
    }
    if (
      currentStep === INVESTMENT_APPLICATION_STEP.ACCEPTANCE &&
      (applicationReviewAcceptance.addNewList.length ||
        applicationReviewAcceptance.removeList.length)
    ) {
      await handleSaveSignatories(false);
    }
    getChangedStep(currentStep + 1);
  };

  const isShowApproveAndReject =
    statusName === applicationStatuses.Submitted ||
    statusName === applicationStatuses.KYC_In_Progress ||
    statusName === applicationStatuses.Action_Required ||
    statusName === applicationStatuses.KYC_Completed ||
    statusName === applicationStatuses.Cancelled ||
    statusName === applicationStatuses.Withdrawn;

  const isDisabledApproveAndReject =
    statusName === applicationStatuses.Cancelled || statusName === applicationStatuses.Withdrawn;

  const isWholeSale = offerType === UnitClassOfferType.Wholesale;
  const isLessThanMinimumAmount =
    !applicationDetail?.isExistingInvestorInFund &&
    investmentAmount < minimumInvestmentAmount &&
    acceptInvestmentAmountUnderMinimum &&
    investmentAmount >= hardFloor;

  const isShowInitiateVerification =
    applicationDetail?.isExistingInvestorInFund &&
    statusName === applicationStatuses.Submitted &&
    !summary?.find((it) => it.id === APPLICATION_APPROVE_STEP.InitiateKYC);

  const isAllowUploadNewDocument =
    !isViewMode &&
    currentStep === INVESTMENT_APPLICATION_STEP.ADDITIONAL &&
    (statusName === applicationStatuses.Submitted ||
      statusName === applicationStatuses.KYC_In_Progress ||
      statusName === applicationStatuses.Action_Required ||
      statusName === applicationStatuses.KYC_Completed);

  const isShowSign =
    statusName !== applicationStatuses.In_Progress && statusName !== applicationStatuses.Pending;
  const isAllowSign =
    currentStep === INVESTMENT_APPLICATION_STEP.ACCEPTANCE &&
    (statusName === applicationStatuses.Submitted ||
      statusName === applicationStatuses.KYC_In_Progress ||
      statusName === applicationStatuses.Action_Required ||
      statusName === applicationStatuses.KYC_Completed);

  const isShowSaveButton = isAllowUploadNewDocument || isAllowSign;

  const approvedInvestorType = applicationDetail?.approvedSteps?.find(
    (it) => it.step === APPLICATION_APPROVE_STEP.InvestorTypeApproved,
  );
  const isApprovedInvestorType = approvedInvestorType
    ? `Approved by ${approvedInvestorType.approvedBy} on ${dayjs(
        approvedInvestorType.approvedDate,
      ).format(DATE_PICKER_FORMAT)}`
    : '';
  const isRejectedInvestorType =
    applicationDetail?.rejectedType === RejectApplicationType.InvestorType
      ? `Rejected by ${applicationDetail.rejectedBy} on ${dayjs(
          applicationDetail.rejectedDate,
        ).format(DATE_PICKER_FORMAT)}`
      : '';
  const isRequiredReviewInvestorType =
    isWholeSale && !(isApprovedInvestorType || isRejectedInvestorType);

  const approvedInvestmentAmount = applicationDetail?.approvedSteps?.find(
    (it) => it.step === APPLICATION_APPROVE_STEP.InvestmentAmountApproved,
  );
  const isApprovedInvestmentAmount = approvedInvestmentAmount
    ? `Approved by ${approvedInvestmentAmount.approvedBy} on ${dayjs(
        approvedInvestmentAmount.approvedDate,
      ).format(DATE_PICKER_FORMAT)}`
    : '';
  const isRejectedInvestmentAmount =
    applicationDetail?.rejectedType === RejectApplicationType.InvestmentAmount
      ? `Rejected by ${applicationDetail.rejectedBy} on ${dayjs(
          applicationDetail.rejectedDate,
        ).format(DATE_PICKER_FORMAT)}`
      : '';
  const isRequiredReviewInvestmentAmount =
    isLessThanMinimumAmount && !(isApprovedInvestmentAmount || isRejectedInvestmentAmount);

  const isRequiredReviewAcceptance = !summary?.find(
    (it) => it.id === APPLICATION_APPROVE_STEP.ManualSigned,
  );

  const disabledSaveButton =
    isLoading ||
    isViewMode ||
    (currentStep === INVESTMENT_APPLICATION_STEP.ADDITIONAL &&
      (!!additionalDocument?.id || !additionalDocument)) ||
    (currentStep === INVESTMENT_APPLICATION_STEP.ACCEPTANCE &&
      !applicationReviewAcceptance.addNewList.length &&
      !applicationReviewAcceptance.removeList.length &&
      applicationReviewAcceptance?.currentSelectedList?.some(
        (f: any) => f?.status === APPLICATION_SIGNATORY_STATUS.Signed,
      )) ||
    isUploadingDocument ||
    isSavingSignatories ||
    upsertApplicationAcceptanceLoading;

  useEffect(() => {
    setApplicationReviewAcceptance((prev) => ({
      ...prev,
      enabled: isShowSign,
      disabled: !isAllowSign || !!isViewMode,
    }));
  }, [statusName, isAllowSign]);

  return (
    <>
      <InvestmentApplicationForm
        {...props}
        onClose={() => null}
        getChangedStep={getChangedStep}
        isViewMode
        hideOfferPreview
        defaultExcludeSteps={{ [INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION]: true }}
        hiddenSaveBtn
        hiddenSubmitBtn
        isReviewing
        fundName={fundDetail?.name}
        isRequiredReviewInvestorType={isRequiredReviewInvestorType}
        isRequiredReviewInvestmentAmount={isRequiredReviewInvestmentAmount}
        isRequiredReviewAcceptance={isRequiredReviewAcceptance}
        isFundApplication={applicationData?.isFundApplication}
        customActionButton={
          <Box flex={1} display='flex' justifyContent={'space-between'}>
            <Box>
              <RejectApplicationAction
                color='error'
                sx={{ alignSelf: 'flex-start', color: 'error.main' }}
                disabled={isLoading || isViewMode || isDisabledApproveAndReject}
                title='Reject Application'
                appId={appId}
                rejectedType={RejectApplicationType.Application}
                statusName={statusName}
              />
            </Box>
            <Box className='flex'>
              {isShowSaveButton && (
                <CustomButton
                  variant='outlined'
                  sx={{ color: 'neutral.ne800' }}
                  onClick={handleSave}
                  disabled={disabledSaveButton}
                  isLoading={isSavingSignatories || upsertApplicationAcceptanceLoading}
                >
                  Save
                </CustomButton>
              )}
              {currentStep !== INVESTMENT_APPLICATION_STEP.ACCEPTANCE && (
                <CustomButton sx={{ ml: 3 }} onClick={handleNext}>
                  Next
                </CustomButton>
              )}
            </Box>
          </Box>
        }
        approveInvestorTypeComponent={
          isWholeSale && (
            <>
              {(isRejectedInvestorType || isApprovedInvestorType) && (
                <Box className='flex items-center absolute left-1/2 bottom-3'>
                  {isRejectedInvestorType ? <CrossCircleFillIcon /> : <CheckCircleFillIcon />}
                  <Typography ml={0.5} variant='body3' color='neutral.ne800'>
                    {isRejectedInvestorType || isApprovedInvestorType}
                  </Typography>
                </Box>
              )}
              <Box
                className={clsx('absolute top-[47px] right-0 gap-4 flex', {
                  '!hidden':
                    !isShowApproveAndReject || !!isRejectedInvestorType || !!isApprovedInvestorType,
                })}
              >
                <RejectApplicationAction
                  appId={appId}
                  sx={{ py: 1, minHeight: '44px', color: 'neutral.ne800' }}
                  disabled={isLoading || isViewMode || isDisabledApproveAndReject}
                  rejectedType={RejectApplicationType.InvestorType}
                  title='Reject'
                  statusName={statusName}
                  alwaysShow
                />

                <CustomButton
                  sx={{ py: 1, minHeight: '44px' }}
                  disabled={isLoading || isDisabledApproveAndReject || isViewMode}
                  isLoading={
                    approvedStep === APPLICATION_APPROVE_STEP.InvestorTypeApproved &&
                    isApprovingApplication
                  }
                  onClick={() =>
                    handleApproveApplication(APPLICATION_APPROVE_STEP.InvestorTypeApproved)
                  }
                >
                  Approve
                </CustomButton>
              </Box>
            </>
          )
        }
        bannerInvestorTypeComponent={
          isWholeSale &&
          isShowApproveAndReject && (
            <Grid item xs={12}>
              <CustomAlert severity='warning'>Investor type requires approval.</CustomAlert>
            </Grid>
          )
        }
        approveInvestmentAmountComponent={
          isLessThanMinimumAmount && (
            <>
              {(isRejectedInvestmentAmount || isApprovedInvestmentAmount) && (
                <Box className='flex items-end flex-1 pb-3 transform -translate-x-[11px]'>
                  {isRejectedInvestmentAmount ? <CrossCircleFillIcon /> : <CheckCircleFillIcon />}
                  <Typography ml={0.5} variant='body3' color='neutral.ne800'>
                    {isRejectedInvestmentAmount || isApprovedInvestmentAmount}
                  </Typography>
                </Box>
              )}
              <Box
                className={clsx('self-end gap-4 flex', {
                  '!hidden':
                    !isShowApproveAndReject ||
                    !!isRejectedInvestmentAmount ||
                    !!isApprovedInvestmentAmount,
                })}
              >
                <RejectApplicationAction
                  appId={appId}
                  sx={{ py: 1, minHeight: '44px', color: 'neutral.ne800' }}
                  disabled={isLoading || isViewMode || isDisabledApproveAndReject}
                  title='Reject'
                  rejectedType={RejectApplicationType.InvestmentAmount}
                  statusName={statusName}
                  alwaysShow
                />

                <CustomButton
                  sx={{ py: 1, minHeight: '44px' }}
                  disabled={isLoading || isDisabledApproveAndReject || isViewMode}
                  isLoading={
                    approvedStep === APPLICATION_APPROVE_STEP.InvestmentAmountApproved &&
                    isApprovingApplication
                  }
                  onClick={() =>
                    handleApproveApplication(APPLICATION_APPROVE_STEP.InvestmentAmountApproved)
                  }
                >
                  Approve
                </CustomButton>
              </Box>
            </>
          )
        }
        bannerInvestorAmountComponent={
          isLessThanMinimumAmount &&
          isShowApproveAndReject && (
            <Grid item xs={12}>
              <CustomAlert severity='warning'>
                Investment amount is below minimum investment amount. Please review and approve.
              </CustomAlert>
            </Grid>
          )
        }
        initiateVerificationComponent={
          <CustomButton
            sx={{ py: 1, minHeight: '44px', mt: 4, width: 'fit-content' }}
            disabled={isViewMode || !isShowInitiateVerification}
            isLoading={isInitiatingVerificationApplication}
            onClick={handleOpenConfirmModal}
          >
            Initiate AML/KYC Verification
          </CustomButton>
        }
        applicationReviewDocument={{
          data: additionalDocument,
          setData: setAdditionalDocument,
          enable: isAllowUploadNewDocument,
          removeDocument: handleDeleteDocument,
        }}
        applicationReviewAcceptance={applicationReviewAcceptance}
        setApplicationReviewAcceptance={setApplicationReviewAcceptance}
      />
      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title={'You did it'}
          description={alertDescription}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertModalRef?.current?.close();
              if (currentStep === INVESTMENT_APPLICATION_STEP.ACCEPTANCE) {
                onCloseApplicationModal();
              }
            },
          }}
        />
      </BasicModal>
      <ConfirmModal
        ref={confirmModalRef}
        title={'Initiate AML/KYC Verification?'}
        content={`This application will be submitted to AML/KYC team for processing. Are you sure you want to continue?`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseConfirmModal}
              disabled={isLoading}
            >
              Cancel
            </CustomButton>
            <CustomButton onClick={handleInitiateVerificationApplication}>Yes</CustomButton>
          </>
        }
      />
    </>
  );
};

export default ApplicationReview;
