import { Box, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import React, { useMemo, useRef } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { htmlToPlainText } from 'src/utils/common';
import { Editor as TinyMCEEditor } from 'tinymce';
import CustomHelperText from './CustomHelperText';

interface ICustomEditor {
  name: string;
  readOnly?: boolean;
  initValue?: string;
  height?: number;
  toolbar?: boolean | string;
  statusbar?: boolean;
  isShowCharactersLeft?: boolean;
  maxLength?: number;
  header?: string;
  footer?: string;
  placeholder?: string;
  customContentStyle?: string;
  skipInitialValue?: boolean;
}

const defaultToolbar = 'bold italic underline | bullist numlist';

const CustomEditor = ({
  name,
  readOnly,
  initValue = '',
  height = 500,
  toolbar = defaultToolbar,
  statusbar = true,
  maxLength = 0,
  isShowCharactersLeft = false,
  header = '',
  footer = '',
  placeholder = '',
  customContentStyle = '',
  skipInitialValue = false
}: ICustomEditor) => {
  const { control } = useFormContext();

  const editorRef = useRef<TinyMCEEditor | null>(null);
  const {
    field: { onChange, value: htmlValue },
    fieldState: { invalid, error },
  } = useController({ control, name });

  const plainTextFromHtml = htmlToPlainText(htmlValue) || '';

  const totalCharactersLeft = useMemo(() => {
    return maxLength - plainTextFromHtml.length;
  }, [htmlValue]);

  React.useEffect(() => {
    if (!skipInitialValue) {
      onChange(initValue);
    }
  }, [initValue, skipInitialValue]);

  return (
    <>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => {
          if (header || footer) {
            let content = `<div>${editor?.getContent()}</div>`;
            const htmlHeader = header ? `<header>${header}</header>` : '';
            const htmlFooter = footer ? `<footer><div>---</div>${footer}</footer>` : '';
            content = htmlHeader + content + htmlFooter;
            editor?.setContent(content);
          }
          editorRef.current = editor;
        }}
        disabled={readOnly}
        onEditorChange={onChange}
        value={htmlValue}
        init={{
          height,
          menubar: false,
          branding: false,
          statusbar,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          toolbar,
          content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } ${customContentStyle}`,
          placeholder: placeholder,
        }}
      />
      {(invalid || isShowCharactersLeft) && (
        <Box className='ml-2 flex items-center justify-between'>
          {invalid ? (
            <CustomHelperText variant={'error'} message={(error as FieldError)?.message} />
          ) : (
            <span />
          )}
          {isShowCharactersLeft && (
            <Typography variant='body3' color='neutral.ne800'>
              {`${totalCharactersLeft} characters left`}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default CustomEditor;
