import { OFFER_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { X_CLIENT_IDS } from 'src/providers/FundPortalProvider';
import {
  IEmailInvitation,
  IOfferById,
  IOfferDetail,
  IOfferDocument,
  IOfferExecutionDeclaration,
  IOfferInvestment,
  IPublishWithEntity,
  IUpsertOfferQuestionnaireBody,
  OfferQueryBody,
} from './type';

export const getOfferList = async (data: OfferQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: OFFER_API.getOfferList.api,
  });

  return result.data;
};

export const getOfferFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getOfferFilter.api,
  });

  return result.data;
};

export const exportOfferList = async (data: OfferQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: OFFER_API.exportOfferList.api,
  });

  return result.data;
};

export const deleteOfferById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: OFFER_API.offerById.api(id),
  });

  return result.data;
};

export const getOfferById = async (id: string, step: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.offerById.api(id),
    params: { step: Number(step) },
  });

  return result.data;
};

export const createOfferDetails = async (data: IOfferDetail) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: OFFER_API.createOfferDetails.api,
    data,
  });
  return result.data;
};

export const updateOfferDetails = async (data: IOfferDetail, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.updateOfferDetails.api(id),
    data,
  });
  return result.data;
};

export const createOffer = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: OFFER_API.offerById.api(id),
  });

  return result.data;
};

export const getInvestmentEntityTypes = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getInvestmentEntityTypes.api,
  });

  return result.data;
};

export const getAllClient = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getAllClient.api,
  });

  return result.data;
};

export const getAllFundByClient = async (clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getAllFundByClient.api(clientId),
  });

  return result.data;
};

export const getAllUnitClassByFund = async (fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getAllUnitClassByFund.api(fundId),
  });

  return result.data;
};

export const upsertOfferInvestmentDetails = async (params: IOfferInvestment, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.upsertOfferInvestmentDetails.api(id),
    data: params,
  });
  return result.data;
};

export const getFundBrandingOfferById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.fundBrandingOfferDetails.api(id),
  });

  return result.data;
};

export const getDeclarationOffer = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getDeclarationOffer.api,
  });

  return result.data;
};

export const upsertOfferExecutionDeclarations = async (
  data: IOfferExecutionDeclaration,
  id: string,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.upsertOfferExecutionDeclarations.api(id),
    data,
  });
  return result.data;
};

export const getOfferUnitClasses = async (offerId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getOfferUnitClasses.api(offerId),
  });
  return result.data;
};

export const getOfferPreviewByUnitClass = async (offerId: string, unitClassId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getOfferPreviewByUnitClass.api(offerId, unitClassId),
  });
  return result.data;
};

export const getOfferClientBranding = async (clientId: string) => {
  const headers: any = {
    common: {
      ...portalApi.defaults.headers.common,
      [X_CLIENT_IDS]: clientId,
    },
  };
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getOfferClientBranding.api,
    headers: headers,
  });
  return result.data;
};

export const suspendOffer = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.suspendOffer.api(id),
  });

  return result.data;
};

export const activateOffer = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.activateOffer.api(id),
  });
  return result.data;
};

export const upsertOfferDocuments = async (data: IOfferDocument, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.upsertOfferDocuments.api(id),
    data,
  });
  return result.data;
};

export const downloadOfferDocuments = async (offerId: string, documentId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.downloadDocument.api(offerId),
    params: {
      documentId,
    },
    responseType: 'arraybuffer',
  });
  return result.data;
};

export const getFundBrandingForOffer = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.getFundBrandingForOffer.api(id),
  });
  return result.data;
};

export const sentEmailInvitation = async (data: IEmailInvitation, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.sendEmailInvitation.api(id),
    data,
  });
  return result.data;
};

export const publishWithEntity = async (
  data: IPublishWithEntity,
  id: string,
  isSentMail: boolean,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: OFFER_API.invitationWithEntity.api(id, isSentMail),
  });

  return result.data;
};

export const getInvitationWithEntity = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.invitationWithEntity.api(id),
  });
  return result.data;
};

export const getInvitationHistories = async (data: any, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: OFFER_API.invitationHistories.api(id),
  });
  return result.data;
};

export const getDuplicateOfferById = async (id: string): Promise<string> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.duplicateOfferById.api(id),
  });

  return resp.data;
};

export const duplicateOfferById = async (id: string, data: IOfferById): Promise<string> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.POST,
    url: OFFER_API.duplicateOfferById.api(id),
    data,
  });

  return resp.data;
};

export const upsertOfferQuestionnaire = async (data: {
  offerId: string;
  body: IUpsertOfferQuestionnaireBody;
}): Promise<string> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: OFFER_API.upsertOfferQuestionnaire.api(data.offerId),
    data: data.body,
  });

  return resp.data;
};

export const downloadOfferDocumentsForQuestionnaire = async (data: {
  offerId: string;
  offerDocumentType: number;
  unitClassId: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: OFFER_API.downloadOfferDocumentsForQuestionnaire.api(data.offerId),
    params: {
      offerDocumentType: data.offerDocumentType,
      unitClassId: data.unitClassId,
    },
    responseType: 'arraybuffer',
  });

  // Access the Content-Disposition header
  const contentDisposition = result.headers['content-disposition'];
  // Extract the filename if it exists
  const filename = contentDisposition
    ? contentDisposition?.split('; ')[1]?.replace('filename=', '')?.replace(/"/g, '')
    : 'document.zip';

  return {
    data: result.data,
    filename,
  };
};
