import { Box, debounce, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { AddIcon } from 'src/assets/icons/AddIcon';

import { toast } from 'react-toastify';
import { CancelIcon } from 'src/assets/icons/CancelIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import SearchField from 'src/components/atoms/SearchField';
import ActionMenus from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import {
  ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS,
  administrationInvestmentEntityStatuses,
} from 'src/constants/administration-investor';
import { ActionType } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useQueryString } from 'src/hooks/useQueryString';
import { useRole } from 'src/hooks/useRole';
import { IAction } from 'src/interfaces/common';
import {
  useArchiveInvestmentEntity,
  useInvestmentEntityExportCSV,
} from 'src/modules/administration-investor/hooks';
import { ConfirmFormat } from 'src/modules/common/type';
import {
  useDeleteInvestmentEntity,
  useGetInvestmentEntityById,
  useGetInvestmentEntityList,
} from 'src/modules/investment-entity/hooks';
import { IInvestment } from 'src/modules/investment-entity/types';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { formatValueWithSpaces, handleErrorFromServer } from 'src/utils/common';
import InvestmentEntityForm, { INVESTMENT_ENTITY_STEP } from './components/InvestmentEntityForm';

interface IInvestmentEntityProps {
  switchLayoutDropdown: React.ReactNode;
  bulkUploadButton: React.ReactNode;
}

interface ITableData extends IInvestment {}

const InvestmentEntityTab: FC<IInvestmentEntityProps> = ({
  bulkUploadButton,
  switchLayoutDropdown,
}) => {
  const investmentEntityModalRef = useRef<IBasicModalElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const errorAlertModalRef = useRef<IBasicModalElement>(null);

  const { entityId = '' } = useQueryString();
  const { selectedClients, selectedFunds } = useFundPortalContext();

  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [selectedRow, setSelectedRow] = useState<ITableData | null>();
  const [actionType, setActionType] = useState<ActionType>('view');
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');

  const { canDownload, canCreate, filterActions } = useRole(
    ROUTES_PATH.ADMINISTRATION_INVESTMENT_ENTITY,
  );

  const {
    data: { items: investmentEntityList = [], metadata: { page, pageSize, totalItem } = {} },
    isLoading,
    setParams,
    refetch,
    params,
  } = useGetInvestmentEntityList();
  const { mutate: deleteInvestmentEntity, isLoading: deleting } = useDeleteInvestmentEntity();
  const { mutate: archiveInvestmentEntity } = useArchiveInvestmentEntity();
  const { mutate: exportCSV, isLoading: isExportLoading } = useInvestmentEntityExportCSV();
  // Use for getting status when navigate from fund application to edit investment entity
  const { data: investmentEntityData } = useGetInvestmentEntityById(
    entityId,
    { step: INVESTMENT_ENTITY_STEP.DETAILS },
    !!entityId,
  );

  const isOneOfFiltersEmpty =
    selectedClients === MULTIPLE_SELECT_EMPTY || selectedFunds === MULTIPLE_SELECT_EMPTY;

  useEffect(() => {
    if (entityId) {
      handleEdit();
    }
  }, [entityId]);

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleSearchInvestmentEntity = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ search: event.target.value });
  }, 300);

  const handleOnChangeArchived = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.checked;
    setParams({ archived: value });
    setIsArchived(!value);
  };

  const handleToggleUpsertModal = (isOpen: boolean) => {
    if (isOpen) {
      investmentEntityModalRef.current?.open();
    } else {
      refetch();
      investmentEntityModalRef.current?.close();
      setSelectedRow(null);
    }
  };

  const handleEdit = (row?: ITableData) => {
    setActionType('edit');
    row && setSelectedRow(row);
    handleToggleUpsertModal(true);
  };

  const handleDownload = () => {
    exportCSV(params, {
      onSuccess: () => {},
      onError: handleErrorFromServer,
    });
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Investment Entity',
      key: 'name',
      sorter: true,
      sortBy: 'name',
      sx: { minWidth: 180, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.name || ''} />,
    },
    {
      title: 'Investor Number',
      key: 'investorNumber',
      sorter: true,
      sortBy: 'investorNumber',
      sx: { minWidth: 180, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.investorNumber || ''} />,
    },
    {
      title: 'Fund',
      key: 'fund',
      sorter: true,
      sortBy: 'fund',
      sx: { minWidth: 180, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.fund || ''} />,
    },
    {
      title: 'Unit Class',
      key: 'unitClass',
      sorter: true,
      sortBy: 'unitClass',
      sx: { minWidth: 180, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.unitClass || ''} />,
    },
    {
      title: 'Type',
      key: 'type',
      sorter: true,
      sortBy: 'type',
      sx: { minWidth: 180, width: '20%' },
      renderNode: (row) => <StringNodeTable value={row?.typeName || ''} />,
    },
    {
      title: 'ACN/ABN',
      key: 'acn',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => {
        const { abn, acn } = row || {};
        const label =
          (abn && acn) || acn ? formatValueWithSpaces(acn) : abn ? formatValueWithSpaces(abn) : '';

        return <StringNodeTable value={label} />;
      },
    },
    {
      title: 'Status',
      key: 'status',
      sorter: true,
      sortBy: 'status',
      isSticky: true,
      sx: { minWidth: 110, width: '10%' },
      renderNode: (row) => {
        return <StatusBadge status={row?.statusName || ''} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      isSticky: true,
      sx: { minWidth: 90, width: 90 },
      renderNode: (row) => {
        const getMenus = {
          [administrationInvestmentEntityStatuses.Draft]: [
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.view,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.edit,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.delete,
          ],
          [administrationInvestmentEntityStatuses.New]: [
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.view,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.edit,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.archive,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.delete,
          ],
          [administrationInvestmentEntityStatuses.NotLinked]: [
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.view,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.edit,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.archive,
          ],
          [administrationInvestmentEntityStatuses.Linked]: [
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.view,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.edit,
            ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.archive,
          ],
        };

        const actionIcons = {
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.view.key]: <DocumentOnePageIcon />,
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.edit.key]: <EditOutlinedIcon />,
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.archive.key]: <CancelIcon />,
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.delete.key]: <DeleteIcon />,
        };

        const actions = {
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.view.key]: () => {
            setActionType('view');
            setSelectedRow(row);
            handleToggleUpsertModal(true);
          },
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.edit.key]: () => handleEdit(row),
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.archive.key]: () => {
            setConfirmFormat({
              title: params.archived
                ? 'Reactivate Investment Entity?'
                : 'Archive Investment Entity?',
              content: `Investment entity will be ${
                params.archived ? 'reactivated' : 'archived'
              }. Are you sure you want to continue?`,
              button: {
                type: 'primary',
                label: 'Continue',
                onAction: () => {
                  archiveInvestmentEntity(row.id, {
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  });
                },
              },
            });
            modalConfirmRef?.current?.open();
          },
          [ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.delete.key]: () => {
            setConfirmFormat({
              title: 'Delete Investment Entity?',
              content: `Are you sure you want to delete the investment entity? <br />
              This action cannot be undone.`,
              button: {
                type: 'error',
                label: 'Delete',
                onAction: () =>
                  deleteInvestmentEntity(row.id, {
                    onSuccess: () => {
                      toast.success('Investment entity deleted successfully.');
                    },
                    onError: (error: any) => {
                      setAlertErrorMessage(error?.data?.ErrorMessage);
                      errorAlertModalRef.current?.open();
                    },
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const permissionActions: IAction[] = filterActions(getMenus[row.statusName], true);

        const menus = permissionActions?.map((menuItem) => ({
          icon: actionIcons?.[menuItem.key],
          label:
            menuItem.key === ADMINISTRATION_INVESTMENT_ENTITY_ACTIONS.archive.key
              ? params?.archived
                ? 'Undo'
                : menuItem.label
              : menuItem.label,
          onAction: actions?.[menuItem.key],
        }));

        return <ActionMenus menus={menus} />;
      },
    },
  ];

  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box component='div' className='flex items-center'>
          <Typography variant='h6'>Investment Entity</Typography>
          {switchLayoutDropdown}
        </Box>
        <Box className='flex'>
          {canDownload && (
            <CustomButton
              sx={(theme) => ({
                backgroundColor: theme.palette.neutral.ne200,
                mr: '10px',
              })}
              startIcon={<DownloadIcon />}
              className='w-[193px] h-12 flex items-center justify-center px-5'
              onClick={handleDownload}
              isLoading={isExportLoading}
            >
              <Typography color='neutral.ne800' variant='body2'>
                Download
              </Typography>
            </CustomButton>
          )}
          {canCreate && (
            <CustomButton
              onClick={() => {
                investmentEntityModalRef.current?.open();
                setActionType('create');
              }}
              startIcon={<AddIcon />}
              className='w-[193px] h-12 flex items-center justify-between px-5'
            >
              <Typography variant='body2'>Create New Entity</Typography>
            </CustomButton>
          )}
        </Box>
      </Box>
      <Box className='flex items-center gap-4 mt-6'>
        <Box width='33%'>
          <SearchField
            placeholder='Search by Investment Entity'
            onChange={handleSearchInvestmentEntity}
          />
        </Box>
        <Box className='flex items-center'>
          <CustomCheckbox
            defaultChecked={isArchived}
            onChange={(event) => handleOnChangeArchived(event)}
            sx={(theme) => ({
              color: theme.palette.neutral.ne500,
            })}
          />
          <Typography variant='body3'>Archived</Typography>
        </Box>
      </Box>
      <Box className='mt-10'>
        {(investmentEntityList.length && !isOneOfFiltersEmpty) || isLoading ? (
          <CustomTable
            columns={columns}
            rows={investmentEntityList}
            isFetchingData={isLoading}
            totalResults={totalItem}
            currentPage={page}
            numberItemOnPage={pageSize}
            onChangePagination={handleChangePage}
            onSort={handleSort}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
      <BasicModal
        ref={investmentEntityModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleToggleUpsertModal(false)}
      >
        <InvestmentEntityForm
          onClose={() => handleToggleUpsertModal(false)}
          id={selectedRow?.id || entityId}
          isEditMode={actionType === 'edit'}
          isViewMode={actionType === 'view'}
          isDraft={
            selectedRow?.statusName === administrationInvestmentEntityStatuses.Draft ||
            investmentEntityData?.statusName === administrationInvestmentEntityStatuses.Draft
          }
          isLinked={
            selectedRow?.statusName === administrationInvestmentEntityStatuses.Linked ||
            investmentEntityData?.statusName === administrationInvestmentEntityStatuses.Linked
          }
        />
      </BasicModal>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
              disabled={deleting}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={deleting}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal ref={errorAlertModalRef}>
        <ConfirmationAlert
          isError
          description={alertErrorMessage}
          buttonAction={{
            label: 'OK',
            onAction: () => errorAlertModalRef?.current?.close(),
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default InvestmentEntityTab;
