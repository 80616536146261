import { createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const RootElement = document.getElementById('root');

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    body3?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    body3: true;
    caption1: true;
    caption2: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    pr50?: string;
    pr100?: string;
    pr200?: string;
    pr300?: string;
    pr400?: string;
    pr600?: string;
    pr500?: string;
    pr700?: string;
    pr800?: string;
    pr900?: string;
    se100?: string;
    se200?: string;
    se300?: string;
    se400?: string;
    se500?: string;
    se600?: string;
    se700?: string;
    se800?: string;
    se900?: string;
    err50?: string;
  }

  interface SimplePaletteColorOptions {
    pr50?: string;
    pr100?: string;
    pr200?: string;
    pr300?: string;
    pr400?: string;
    pr600?: string;
    pr500?: string;
    pr700?: string;
    pr800?: string;
    pr900?: string;
    se100?: string;
    se200?: string;
    se300?: string;
    se400?: string;
    se500?: string;
    se600?: string;
    se700?: string;
    se800?: string;
    se900?: string;
    pr1000?: string;
    err50?: string;
  }

  interface Palette {
    base: {
      [key: string]: string;
    };
    neutral: {
      [key: string]: string;
    };
    sematic: {
      [key: string]: string;
    };
    overlay: {
      [key: string]: string;
    };
    table: Palette['primary'];
    colors: {
      [key: string]: string;
    };
  }

  interface PaletteOptions {
    base: {
      [key: string]: string;
    };
    neutral: {
      [key: string]: string;
    };
    sematic: {
      [key: string]: string;
    };
    overlay: {
      [key: string]: string;
    };
    table: PaletteOptions['primary'];
    colors?: {
      [key: string]: string;
    };
  }
}

export const baseFont = `'SF Pro Display', sans-serif`;
export const bodyFont = `'SF Pro Display', sans-serif`;

const regular = 400;
const medium = 500;
const bold = 700;

const themeCustom = createTheme({
  palette: {
    primary: {
      main: '#003E4E',
      pr50: '#E9F8FF',
      pr100: '#B1D8E4',
      pr200: '#8EBACA',
      pr300: '#699DAF',
      pr400: '#4D889C',
      pr500: '#2D7388',
      pr600: '#216578',
      pr700: '#105163',
      pr900: '#002A37',
      pr1000: '#063E4D',
    },
    secondary: {
      main: '#33B27F',
      se100: '#E3F4EC',
      se200: '#BCE3D0',
      se300: '#8FD0B2',
      se400: '#5FBF95',
      se500: '#33B27F',
      se600: '#00A56A',
      se700: '#00965F',
      se800: '#007346',
      se900: '#005430',
    },
    base: {
      black: '#1C1C1C',
      white: '#FFFFFF',
      bg: '#f9f9f9',
    },
    neutral: {
      ne100: '#F9F9F9',
      ne200: '#EDEEEE',
      ne300: '#D1D3D4',
      ne400: '#C2C4C5',
      ne500: '#9DA0A1',
      ne600: '#858A8B',
      ne700: '#20253A',
      ne800: '#686D6E',
      ne900: '#394042',
      ne1000: '#E8EBEC',
      ne1100: '#D9D9D9',
      ne1200: '#8C8C8C',
    },
    overlay: {
      black: '#040A01',
      grey: '#E9E9E9',
    },
    error: {
      main: '#FF3F52',
      light: '#FFE2D8',
      dark: '#5C0011',
      err50: '#FFF1F0',
    },
    warning: {
      main: '#FFB302',
      light: '#FFF6CC',
    },
    info: {
      main: '#00AEEF',
      light: '#E4F3FC',
    },
    success: {
      main: '#33B27F',
      light: '#E3F4EC',
    },
    sematic: {
      success100: '#E3F4EC',
      success500: '#33B27F',
      information100: '#E4F3FC',
      information500: '#00AEEF',
      warning100: '#FFF6CC',
      warning500: '#FFB302',
      danger100: '#FFE2D8',
      danger500: '#FF3F52',
      inReview100: '#EBE9FF',
      inReview500: '#4A41D6',
      actionRequired100: '#FFEFD0',
      actionRequired500: '#FC6200',
      awaitingDeposit100: '#F9EFCF',
      awaitingDeposit500: '#AD6800',
      finalised100: '#EFFAE7',
      finalised500: '#4E911A',
      kycInProgress100: '#F9F0FF',
      kycInProgress500: '#9254DE',
      withdrawn100: '#FFF0FC',
      withdrawn500: '#C63AAD',
    },
    table: {
      main: '#f6f8fc',
      contrastText: '#000',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: bodyFont,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 16,
          borderRadius: 12,
          backgroundColor: theme.palette.base.white,
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.neutral.ne400,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          height: 46,
        }),
        multiline: () => ({
          height: 'unset',
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginRight: 8,
          marginLeft: 8,
          lineHeight: '16px',
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 8,
          marginTop: 4,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 12,
          boxShadow: 'none',
          color: theme.palette.base.white,
          maxHeight: 48,
          fontSize: 16,
        }),
        sizeLarge: {
          padding: '12px 40px',
        },
        sizeMedium: {
          padding: '8px 32px',
        },
        sizeSmall: {
          padding: '5px 24px',
          fontSize: 14,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          maxWidth: 48,
          maxHeight: 48,
          borderRadius: 12,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        container: RootElement,
      },
      styleOverrides: {
        container: {
          alignItems: 'start',
        },
        paper: {
          margin: 0,
          top: '20%',
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        container: RootElement,
      },
      styleOverrides: {
        root: {
          zIndex: 1300,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.overlay.black,
          opacity: '0.35 !important',
        }),
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: 46,
        },
        inputRoot: {
          padding: '11px 16px',
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        container: RootElement,
      },
    },
    MuiPopover: {
      defaultProps: {
        container: RootElement,
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: '16px',
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          fontSize: '16px',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },

    fontFamily: baseFont,

    h1: {
      fontSize: '64px',
      lineHeight: '72px',
      fontWeight: bold,
    },
    h2: {
      fontSize: '56px',
      lineHeight: '64px',
      fontWeight: bold,
    },
    h3: {
      fontSize: '48px',
      lineHeight: '56px',
      fontWeight: bold,
    },
    h4: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: bold,
    },
    h5: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: bold,
    },
    h6: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: bold,
    },
    subtitle1: {
      fontSize: '32px',
      lineHeight: '24px',
      fontWeight: medium,
    },
    subtitle2: {
      fontSize: '28px',
      lineHeight: '20px',
      fontWeight: medium,
    },
    subtitle3: {
      fontSize: '24px',
      lineHeight: '16px',
      fontWeight: medium,
    },
    subtitle4: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: bold,
    },
    body1: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: regular,
    },
    body2: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: regular,
    },
    body3: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: regular,
      fontFamily: bodyFont,
    },
    caption1: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: regular,
    },
    caption2: {
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: regular,
    },
  },
});

export default themeCustom;
