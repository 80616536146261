import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface IStringNodeTableProps {
  value: ReactNode;
}

const StringNodeTable: FC<IStringNodeTableProps & TypographyProps> = ({
  value,
  className = '',
  color,
  ...props
}) => {
  return (
    <Typography
      className={clsx('break-words', className)}
      variant='body2'
      color={color || 'neutral.ne800'}
      {...props}
    >
      {value}
    </Typography>
  );
};

export default StringNodeTable;
