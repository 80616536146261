import { isNumber } from 'lodash';
import { BRANDING_TYPE, QUESTIONNAIRE_ERROR_MESSAGE, REQUIRE_MESSAGE } from 'src/constants/common';
import { EXECUTION_METHOD } from 'src/constants/offer';
import { QUESTIONNAIRE_ANSWER_TYPE } from 'src/modules/offer/type';
import * as Yup from 'yup';
import { YupBase } from './common';

export const offerInvestmentDetailSchema = Yup.object().shape({
  investmentEntityTypes: Yup.string().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.array().of(Yup.number())
      : Yup.array().of(Yup.number()).min(1, REQUIRE_MESSAGE);
  }),
  percentage: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.mixed().nullable()
      : YupBase.transformNumberSchema
          .nullable()
          .typeError(REQUIRE_MESSAGE)
          .test('validatePercentage', REQUIRE_MESSAGE, function (value) {
            return isNumber(value) || isNumber(this.parent.fixedAmount) || !this.parent.capitalCall;
          });
  }),
  fixedAmount: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.mixed().nullable()
      : YupBase.transformNumberSchema
          .nullable()
          .typeError(REQUIRE_MESSAGE)
          .test('validateFixedAmount', REQUIRE_MESSAGE, function (value) {
            return isNumber(value) || isNumber(this.parent.percentage) || !this.parent.capitalCall;
          });
  }),
  capitalCall: Yup.boolean().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.boolean() : Yup.boolean().required(REQUIRE_MESSAGE);
  }),
  isSaveDraft: Yup.boolean(),
});

export const offerDetailsSchema = Yup.object().shape({
  name: Yup.string().when(['isSaveDraft'], {
    is: true,
    otherwise: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
  expirationDate: Yup.mixed().when(['isSaveDraft', 'isOpenEnded'], {
    is: (isSaveDraft: boolean, isOpenEnded: boolean) => {
      return isSaveDraft || isOpenEnded;
    },
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
  clientId: Yup.string().required(REQUIRE_MESSAGE),
  fundId: Yup.string().required(REQUIRE_MESSAGE),
  unitClassIds: Yup.array().when(['isSaveDraft'], {
    is: true,
    otherwise: (schema) => schema.min(1, REQUIRE_MESSAGE),
  }),
});

export const offerDocumentsSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .when(['isSaveDraft'], {
      is: true,
      otherwise: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  overview: Yup.string()
    .nullable()
    .when(['isSaveDraft'], {
      is: true,
      otherwise: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  brandings: Yup.array().when(['isSaveDraft'], {
    is: true,
    otherwise: (schema) =>
      schema.test('required', REQUIRE_MESSAGE, (value) => {
        return !!value?.find((it) => it.brandingType === BRANDING_TYPE.Banner);
      }),
  }),
  documents: Yup.array().when(['isSaveDraft'], {
    is: true,
    otherwise: (schema) =>
      schema.of(
        Yup.object({ documents: Yup.array(), fundDocuments: Yup.array() }).test(
          'length',
          'At least 1 document is required.',
          (value) => {
            return (value?.documents?.length || 0) + (value?.fundDocuments?.length || 0) >= 1;
          },
        ),
      ),
  }),
});

export const executionDeclarationSchema = Yup.object().shape({
  declaration: Yup.string().when(['isExecutionMethod3'], {
    is: true,
    otherwise: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
  executionMethods: Yup.array().when(['isSaveDraft'], {
    is: true,
    otherwise: (schema) =>
      schema
        .of(
          Yup.object().shape({
            executionMethod: Yup.number(),
            isSelect: Yup.boolean(),
            email: Yup.string()
              .nullable()
              .when(['executionMethod', 'isSelect'], {
                is: (executionMethod: EXECUTION_METHOD, isSelect: boolean) =>
                  executionMethod === EXECUTION_METHOD.ManualEmail && isSelect,
                then: () => YupBase.email,
              }),
            address: Yup.object()
              .nullable()
              .when(['executionMethod', 'isSelect'], {
                is: (executionMethod: EXECUTION_METHOD, isSelect: boolean) =>
                  executionMethod === EXECUTION_METHOD.ManualPost && isSelect,
                then: (schema) =>
                  schema.shape({
                    country: Yup.mixed().test(
                      'require',
                      REQUIRE_MESSAGE,
                      (value: any) => value?.id,
                    ),
                    state: Yup.mixed().test('require', REQUIRE_MESSAGE, (value: any) => value?.id),
                    city: Yup.mixed().test(
                      'require',
                      REQUIRE_MESSAGE,
                      (value: any) => (typeof value === 'string' && value) || value?.id,
                    ),
                    postcode: Yup.string().nullable().required(REQUIRE_MESSAGE),
                    streetAddress: Yup.string().nullable().required(REQUIRE_MESSAGE),
                  }),
              }),
          }),
        )
        .test('length', 'At least 1 method is required.', (value) => {
          return !!value?.filter((it) => it.isSelect)?.length;
        }),
  }),
});

export const financialDetailsSchema = Yup.object().shape(
  {
    bankName: Yup.string()
      .when(['accountName', 'bankStateBranch', 'accountNumber'], {
        is: (accountName: string, bankStateBranch: number, accountNumber: number) =>
          (accountName && accountName.length > 0) || bankStateBranch || accountNumber,
        then: () => Yup.string().required(REQUIRE_MESSAGE),
      })
      .nullable(),
    accountName: Yup.string()
      .when(['bankName', 'bankStateBranch', 'accountNumber'], {
        is: (bankName: string, bankStateBranch: number, accountNumber: number) =>
          (bankName && bankName.length > 0) || bankStateBranch || accountNumber,
        then: () => Yup.string().required(REQUIRE_MESSAGE),
      })
      .nullable(),
    bankStateBranch: Yup.string()
      .when(['bankName', 'accountName', 'accountNumber'], {
        is: (accountName: string, bankName: string, accountNumber: number) =>
          (accountName && accountName.length > 0) ||
          (bankName && bankName.length > 0) ||
          accountNumber,
        then: () =>
          Yup.string()
            .required(REQUIRE_MESSAGE)
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits'),
      })
      .nullable(),
    accountNumber: Yup.string()
      .when(['bankName', 'accountName', 'bankStateBranch'], {
        is: (bankName: string, accountName: string, bankStateBranch: number) =>
          (bankName && bankName.length > 0) ||
          (accountName && accountName.length > 0) ||
          bankStateBranch,
        then: () =>
          Yup.string()
            .required(REQUIRE_MESSAGE)
            .matches(/^[0-9]+$/, 'Must be only digits'),
      })
      .nullable(),
  },
  [
    ['bankName', 'bankStateBranch'],
    ['bankName', 'accountName'],
    ['bankName', 'accountNumber'],
    ['accountName', 'bankStateBranch'],
    ['accountName', 'accountNumber'],
    ['bankStateBranch', 'accountNumber'],
  ],
);

export const sendInvitationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRE_MESSAGE),
  email: YupBase.email,
});

export const questionnaireSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean().nullable(),
  errorMessage: Yup.string().required(REQUIRE_MESSAGE),
  isQuestionnaireRequired: Yup.boolean(),
  questionnaireDetails: Yup.array().when(['isSaveDraft', 'isQuestionnaireRequired'], {
    is: (isSaveDraft: boolean, isQuestionnaireRequired: boolean) =>
      !isSaveDraft && isQuestionnaireRequired,
    then: (schema) =>
      schema.of(
        Yup.object().shape({
          type: Yup.number().required(REQUIRE_MESSAGE),
          question: Yup.string().required(REQUIRE_MESSAGE),
          yesNoPass: Yup.boolean()
            .when('type', {
              is: QUESTIONNAIRE_ANSWER_TYPE.YES_NO,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          maximumFreeTextLength: Yup.number()
            .when('type', {
              is: QUESTIONNAIRE_ANSWER_TYPE.FREE_TEXT,
              then: (schema) =>
                schema
                  .test('required', REQUIRE_MESSAGE, (value) => !!value)
                  .typeError(REQUIRE_MESSAGE),
            })
            .nullable(),
          answers: Yup.array()
            .when('type', {
              is: QUESTIONNAIRE_ANSWER_TYPE.MULTI_OPTION,
              then: (schema) =>
                schema
                  .of(
                    Yup.object().shape({
                      answer: Yup.string().required(REQUIRE_MESSAGE),
                      pass: Yup.boolean().nullable(),
                    }),
                  )
                  .test('passValidation', QUESTIONNAIRE_ERROR_MESSAGE, function (value) {
                    return value?.some((it) => it.pass);
                  }),
            })
            .nullable(),
        }),
      ),
  }),
});
