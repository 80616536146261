import {
  Box,
  Stack,
  StandardTextFieldProps,
  styled,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import CustomHelperText from './CustomHelperText';
import { CustomTooltip, TooltipPlacement } from './CustomTooltip';

export interface IFormInputProps extends StandardTextFieldProps {
  name: string;
  rules?: Record<string, unknown>;
  isRequired?: boolean;
  optional?: ReactNode;
  defaultMessage?: string;
  readOnly?: boolean;
  maxLength?: number;
  labelClass?: string;
  formatValue?: (_: string) => string;
  isShowCharactersLeft?: boolean;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipSX?: SxProps;
  hideErrorMessage?: boolean;
}

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.neutral.ne200,
    paddingRight: 0,
    '& fieldset': {
      borderColor: theme.palette.neutral.ne400,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& fieldset': {
      borderWidth: 1,
    },
  },
  '& textarea': {
    padding: 0,
  },
}));

const FormInput: React.FC<IFormInputProps> = ({
  name,
  label,
  defaultValue = '',
  rules = {},
  defaultMessage = '',
  tooltip,
  disabled,
  optional,
  readOnly,
  maxLength,
  labelClass,
  formatValue,
  isShowCharactersLeft,
  onChange: handleChange,
  tooltipPlacement = 'right-end',
  tooltipSX = {},
  hideErrorMessage,
  ...rest
}) => {
  const { control, watch } = useFormContext();
  const value = watch(name);

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const renderTooltip = () => {
    if (tooltip) {
      return (
        <CustomTooltip title={tooltip} placement={tooltipPlacement} sx={tooltipSX}>
          <span className='cursor-pointer ml-[6px]'>
            <InfoIcon />
          </span>
        </CustomTooltip>
      );
    }
  };

  const totlCharactersLeft = (maxLength || 0) - (value || '').length;

  return (
    <Stack className='w-full' gap={0.5}>
      {(label || optional) && (
        <Box className='flex items-center justify-between' minHeight='20px'>
          <Box className='flex items-center w-full'>
            <Typography
              variant='body3'
              sx={{ width: optional ? 'auto' : '100%' }}
              className={labelClass}
            >
              {label}
            </Typography>
            {renderTooltip()}
          </Box>
          <Typography variant='body3' color='neutral.ne800'>
            {optional}
          </Typography>
        </Box>
      )}
      <CustomTextField
        {...inputProps}
        {...rest}
        {...(formatValue ? { value: formatValue?.(value) } : {})}
        inputRef={ref}
        error={invalid}
        disabled={disabled}
        inputProps={{ readOnly, maxLength }}
        onChange={(e) => {
          onChange(e);
          handleChange && handleChange(e);
        }}
        {...(rest?.multiline ? { onKeyDown: (e) => e.stopPropagation() } : {})}
      />
      {((invalid && !hideErrorMessage) || (isShowCharactersLeft && maxLength)) && (
        <Box className='ml-2 flex items-center justify-between'>
          {invalid && !hideErrorMessage ? (
            <CustomHelperText
              variant={invalid ? 'error' : 'default'}
              message={invalid ? (error as FieldError)?.message : defaultMessage}
            />
          ) : (
            <span />
          )}
          {isShowCharactersLeft && maxLength && (
            <Typography variant='body3' color='neutral.ne800'>
              {`${totlCharactersLeft} characters left`}
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default FormInput;
