/* eslint-disable autofix/no-unused-vars */
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  SxProps,
  Typography,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import CircleCheckIcon from 'src/assets/icons/CircleCheckIcon';
import CustomHelperText from './CustomHelperText';

export interface RadioOption {
  label: string;
  value: string | number;
  disabled?: boolean;
  icon?: ReactNode;
}

interface IFormRadioGroupProps extends RadioGroupProps {
  name: string;
  label?: string | React.ReactNode;
  rules?: Record<string, unknown>;
  options: RadioOption[];
  isEnumValue?: boolean;
  isBooleanValue?: boolean;
  disabled?: boolean;
  optionGap?: number;
  hiddenCheckIcon?: boolean;
  onChangeValue?: (value: any) => void;
  renderOptions?: (
    options: RadioOption[],
    selectedValue?: string | number,
    disabled?: boolean,
  ) => ReactNode;
  containerSx?: SxProps;
  formControlLabelSx?: SxProps;
  isShowIconErrorText?: boolean;
  showPickerOnly?: boolean;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  fullWidth?: boolean;
  hideErrorMessage?: boolean;
}

export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-root': {
    fontSize: 16,
  },
}));

const FormRadioGroup: React.FC<IFormRadioGroupProps> = ({
  name,
  label,
  defaultValue = '',
  rules = {},
  options: defaultOptions,
  isEnumValue,
  isBooleanValue,
  disabled,
  optionGap = 0,
  hiddenCheckIcon = false,
  onChangeValue,
  renderOptions,
  containerSx,
  formControlLabelSx,
  isShowIconErrorText = true,
  showPickerOnly,
  direction = 'row',
  fullWidth = true,
  hideErrorMessage,
  ...rest
}) => {
  const { control, watch } = useFormContext();
  const watchValue = watch(name);

  const options =
    showPickerOnly &&
    disabled &&
    watchValue !== null &&
    watchValue !== undefined &&
    watchValue !== ''
      ? defaultOptions?.filter((it) => it.value === watchValue)
      : defaultOptions;

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (value: string | number) => {
    onChangeValue?.(value);
  };

  const renderRadio = (option: RadioOption) => {
    const classError = {
      color: invalid ? 'sematic.danger500' : '',
    };
    if (option?.icon) {
      return (
        <Radio
          sx={classError}
          checkedIcon={
            <Box
              className='border-2 px-2 py-1.5 rounded-[15px] relative'
              borderColor='primary.pr500'
            >
              {!hiddenCheckIcon && (
                <CircleCheckIcon className='absolute top-0 right-0 translate-x-1/2 translate-y-[-50%]' />
              )}
              {option.icon}
            </Box>
          }
          icon={
            <Box className='border-2 px-2 py-1.5 rounded-[15px]' borderColor='neutral.ne500'>
              {option.icon}
            </Box>
          }
        />
      );
    }

    return <Radio sx={classError} />;
  };

  return (
    <Stack className={clsx(fullWidth ? 'w-full' : 'w-fit')} gap={0.5} sx={containerSx}>
      <Typography variant='body3' className='flex'>
        {label}
      </Typography>
      <FormControl fullWidth error={invalid}>
        <RadioGroup
          {...rest}
          {...inputProps}
          onChange={(e) => {
            const { value } = e.target;
            const valueFormat: any = isEnumValue
              ? Number(value)
              : isBooleanValue
              ? value === 'true'
              : value;
            onChange(valueFormat);
            handleChange(valueFormat);
          }}
          ref={ref}
        >
          {renderOptions ? (
            renderOptions(options, watchValue, disabled)
          ) : (
            <Stack direction={direction} gap={optionGap}>
              {options.map((option) => (
                <CustomFormControlLabel
                  key={String(option.value)}
                  value={option.value}
                  control={renderRadio(option)}
                  label={option.label}
                  disabled={disabled || option.disabled}
                  sx={{
                    flexDirection: option.icon ? 'column' : 'row',
                    textAlign: 'center',
                    ...(formControlLabelSx || {}),
                  }}
                />
              ))}
            </Stack>
          )}
        </RadioGroup>
        {invalid && !hideErrorMessage && (
          <Box>
            <CustomHelperText
              variant='error'
              message={(error as FieldError)?.message || ''}
              isShowIcon={isShowIconErrorText}
            />
          </Box>
        )}
      </FormControl>
    </Stack>
  );
};

export default FormRadioGroup;
