import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import * as React from 'react';
import { CheckCircleFillIcon } from 'src/assets/icons/CheckCircleFillIcon';
import { QUESTIONNAIRE_OFFER_DOCUMENT, YES_NO_ANSWER_LABEL } from 'src/constants/offer';
import { detectPortalType } from 'src/helpers/common';
import { showToast } from 'src/helpers/toast';
import {
  useDownloadApplicationQuestionnaireDocument,
  useGetApplicationById,
} from 'src/modules/applications/hooks';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { useDownloadOfferDocumentsForQuestionnaire } from 'src/modules/offer/hooks';
import { QUESTIONNAIRE_ANSWER_TYPE } from 'src/modules/offer/type';
import { utcToLocalTimezone } from 'src/utils/time';
import { INVESTMENT_APPLICATION_STEP } from './InvestmentApplicationForm';

interface IQuestionnaireViewerProps extends IUpsertInvestmentApplicationForm {
  isRetailOffer?: boolean;
}

const QuestionnaireViewer: React.FunctionComponent<IQuestionnaireViewerProps> = ({
  isRetailOffer,
  id = '',
  offerId = '',
  unitClassId = '',
}) => {
  const { isFunds } = detectPortalType();

  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.QUESTIONNAIRE,
  });

  const { mutate: downloadDocumentOnClientPortal } = useDownloadOfferDocumentsForQuestionnaire();
  const { mutate: downloadDocument } = useDownloadApplicationQuestionnaireDocument();

  const handleDownloadOfferDocuments = (offerDocumentType: number) => {
    if (isFunds) {
      downloadDocumentOnClientPortal(
        {
          offerDocumentType,
          offerId,
          unitClassId,
        },
        {
          onError: () => {
            showToast('Document does not exist', 'error');
          },
        },
      );

      return;
    }

    downloadDocument(
      {
        offerDocumentType,
        offerId,
        unitClassId,
      },
      {
        onError: () => {
          showToast('Document does not exist', 'error');
        },
      },
    );
  };

  const questions =
    applicationDetail?.questionnaire?.items?.sort((a, b) => a.order - b.order) || [];

  return (
    <Box className='questionnaire-container'>
      <Box className='questionnaire-header'>
        <Typography className='mb-2' variant='body1' fontWeight={500}>
          {isRetailOffer
            ? `We need to ask you a few general questions about your investment goals to determine
    whether you are likely to be in the target market for this investment opportunity.`
            : `We need to ask you a few general questions.`}
        </Typography>
        {isRetailOffer && (
          <Typography className='text-[rgba(0, 0, 0, 0.70)] inline-block' variant='body3' pb={4}>
            As part of our obligations under the Design and Distribution Obligations (DDO) outlined
            under the Corporations Act, we are required to gather information about your attributes
            as an investor. Prior to making any selection to the requested information below, you
            are required to have read the{' '}
            <span
              className='underline cursor-pointer font-bold'
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadOfferDocuments(QUESTIONNAIRE_OFFER_DOCUMENT.TMD);
              }}
            >
              Target Market Determination.
            </span>
          </Typography>
        )}
      </Box>

      <Box className='questionnaire-content'>
        <Box className='flex gap-6 pb-6'>
          <Typography className='w-1/2' variant='body2' fontWeight={700}>
            Questions
          </Typography>
          <Typography className='w-1/2' variant='body2' fontWeight={700}>
            Answers
          </Typography>
        </Box>

        <Box className='questions-answers flex flex-col gap-6 pb-9'>
          {questions.map((question, index) => {
            const type = question.type;
            let answer = '';

            switch (type) {
              case QUESTIONNAIRE_ANSWER_TYPE.MULTI_OPTION:
                answer = question.answers?.filter((item) => item.selected)?.[0]?.answer || '';
                break;
              case QUESTIONNAIRE_ANSWER_TYPE.YES_NO:
                answer = question.yesNoPass ? YES_NO_ANSWER_LABEL.YES : YES_NO_ANSWER_LABEL.NO;
                break;
              case QUESTIONNAIRE_ANSWER_TYPE.FREE_TEXT:
                answer = question.answers?.[0]?.answer || '';
                break;
            }

            return (
              <Box key={question.id} className='flex gap-6'>
                <Typography className='w-1/2' variant='body2'>
                  {index + 1}. {question.question}
                </Typography>
                <Typography className='w-1/2 text-disabled-100 break-words' variant='body2'>
                  {answer}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <FormControlLabel
          sx={{ m: 0, alignItems: 'flex-start' }}
          control={<Checkbox checked disabled className='p-0' />}
          disabled
          label={
            isRetailOffer ? (
              <Typography variant='body3' color='base.black' ml={1}>
                I/we confirm that the responses provided to the Target Market Determination
                questions shown above are true and accurate, reflect my/our current investment
                attributes and objectives, and that I/we have read and understood the{' '}
                <span
                  className='font-bold cursor-pointer underline'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadOfferDocuments(QUESTIONNAIRE_OFFER_DOCUMENT.OFFER);
                  }}
                >
                  Offer Documents
                </span>{' '}
                and{' '}
                <span
                  className='font-bold cursor-pointer underline'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadOfferDocuments(QUESTIONNAIRE_OFFER_DOCUMENT.TMD);
                  }}
                >
                  Target Market Determination.
                </span>{' '}
                I/we confirm that if the responses have been completed by a third party delegate
                that I/we have read, understood and agree with the answers provided.
              </Typography>
            ) : (
              <Typography variant='body3' color='base.black' ml={1}>
                I/we confirm that the responses provided are true and accurate, and that I/we have
                read and understood the{' '}
                <span
                  className='font-bold cursor-pointer underline'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadOfferDocuments(QUESTIONNAIRE_OFFER_DOCUMENT.OFFER);
                  }}
                >
                  Offer Document
                </span>
              </Typography>
            )
          }
        />

        <Typography
          className='text-[rgba(0,0,0,0.70)] flex items-center gap-2 mt-6'
          variant='body3'
        >
          <CheckCircleFillIcon />
          {`Declaration signed by ${
            applicationDetail?.questionnaire?.signedByName || ''
          } on ${utcToLocalTimezone(applicationDetail?.questionnaire?.signedTime)}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default QuestionnaireViewer;
