import dayjs from 'dayjs';
import { EXECUTION_METHOD } from 'src/constants/offer';
import { UnitClassOfferType } from 'src/constants/unit-class';
import { BaseQuery } from 'src/modules/common/hooks';
import { IUploadFile } from 'src/modules/common/type';
import { Status } from 'src/modules/users-management/type';

export interface Offer {
  id: string;
  name: string;
  status: number;
  statusName: string;
  createdDate: string;
  createdBy: string;
  expirationDate: string;
  completedApplications: number;
  inProgressApplications: number;
  isExpired: boolean;
  fundName: string;
}

export interface OfferFilter {
  statuses: Status[];
}

export interface OfferQueryBody extends BaseQuery {
  statuses?: number[];
}

export interface IUpsertOfferForm {
  isDuplicate?: boolean;
  isEditMode?: boolean;
  isViewMode?: boolean;
  isCreateMode?: boolean;
  isDraft?: boolean;
  offerId?: string;
  step?: number;
}

export interface IOfferDetail {
  isSaveDraft: boolean;
  name: string;
  expirationDate: dayjs.Dayjs | null;
  isOpenEnded: boolean;
  clientId: string;
  fundId: string;
  unitClassIds: string[];
}

interface IBrandingDetail extends IUploadFile {
  id?: string;
  brandingType: number;
  link?: string;
}

export interface IDocument extends IUploadFile {
  id?: string;
  documentId?: string;
  documentType: number;
  otherDescription?: string;
}

export interface IOfferDocument {
  isSaveDraft: boolean;
  defaultBrandingToOffer: boolean;
  description: string;
  overview: string;
  brandings: IBrandingDetail[];
  documents: {
    unitClassId: string;
    unitClassName: string;
    unitClassCode: string;
    documents: IDocument[];
    fundDocuments: IDocument[];
  }[];
}
export interface IOfferDocumentForm extends IOfferDocument {
  documents: (ArrayElement<IOfferDocument['documents']> & {
    [key: `documentType-${number}`]: number | undefined;
    [key: `description-${number}`]: string | undefined;
  })[];
}

export interface IOfferInvestment {
  isSaveDraft: boolean;
  investmentEntityTypes: number[];
  capitalCall: number | boolean;
  percentage: number | null;
  fixedAmount: number | null;
  isGenerateDepositLetter: boolean;
}

export interface IOfferInvestmentEntityTypes {
  name: string;
  id: number;
}

export interface IOfferById {
  investmentDetails: IOfferInvestment;
  detail: IOfferDetail;
  documents: IOfferDocument;
  executionAndDeclarations: IOfferExecutionDeclaration;
  questionnaires?: IQuestionFormItem[];
  errorMessage?: string;
  isDuplicated?: boolean;
  id?: string;
}

export interface IOfferExecutionDeclaration {
  isSaveDraft: boolean;
  declaration: string;
  defaultToFund: boolean;
  executionMethods: {
    executionMethod: EXECUTION_METHOD;
    isSelect: boolean;
    email?: string;
    address?: {
      countryName?: string;
      countryId?: string;
      stateName?: string;
      stateId?: string;
      cityName?: string;
      cityId?: string;
      postcode?: string;
      streetAddress?: string;
      isUseSameAsPhysicalAddress?: boolean;
      manualEntry?: boolean;
    };
  }[];
}

export interface IOfferUnitClass {
  id: string;
  code: string;
  name: string;
  offerType: number;
}

export interface IOfferPreview {
  id: string;
  name: string;
  fundName: string;
  clientName: string;
  logoUrl: string;
  bannerUrl: string;
  overview: string;
  expirationDate: string;
  isOpenEnded: boolean;
  isInvitation?: boolean;
  isFavourite?: boolean;
  description: string;
  unitClass: {
    id: string;
    name: string;
    code: string;
    offerType: string;
    minimumInvestment: number;
    documents: {
      fileName: string;
      path: string;
      fileSize: number;
      fileType: string | number;
      id?: string;
      documentType: number;
      otherDescription?: string;
    }[];
    investorContactType: number;
    email: string;
    contactNumber: string;
    firstName: string;
    lastName: string;
    position: string;
    countryFlagCode: string;
    organisationName: string;
    contactName: string;
  };
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
}

export interface IOfferClientBranding {
  logoLink: string;
  id: string;
  backgroundNavigation: string;
  backgroundActive: string;
  text: string;
  primaryBackground: string;
  primaryText: string;
  contactLawyerBackground: string;
  contactLawyerText: string;
  hyperlinkText: string;
  defaultToStandard?: false;
}

export interface IOfferInvestorBranding {
  primaryBackground: string;
  primaryText: string;
}

export interface IEmailInvitation {
  email: string;
  name: string;
}

export interface IPublishWithEntity {
  url: string;
  existingInvestors: IExistingInvestor[];
}

export interface IExistingInvestor {
  fundId: string;
  fundName: string;
  isCurrentFund?: boolean;
  investmentEntities: IOfferInvestmentEntityTypes[];
}

export enum QUESTIONNAIRE_ANSWER_TYPE {
  MULTI_OPTION,
  YES_NO,
  FREE_TEXT,
}

export interface IAnswerFormItem {
  key: string;
  id?: string;
  answer: string;
  pass?: boolean;
  order?: number;
}

export interface IQuestionFormItem {
  key?: string;
  id?: string;
  question: string;
  type?: QUESTIONNAIRE_ANSWER_TYPE;
  yesNoPass?: boolean | null;
  maximumFreeTextLength?: number;
  order?: number;
  answers?: IAnswerFormItem[];
}

export interface IQuestionnaireForm {
  isSaveDraft: boolean;
  isQuestionnaireRequired: boolean;
  questionnaireDetails: IQuestionFormItem[];
  errorMessage: string;
  unitClassOfferType?: UnitClassOfferType;
}

export interface IUpsertOfferQuestionnaireBody extends IQuestionnaireForm {}
