import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { OFFER_API } from 'src/constants/apiEndpoints';
import { BaseQuery, DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { IOverarchingFilter, Paginated } from 'src/modules/common/type';
import { IUnitClass } from 'src/modules/funds-management/type';
import { downloadFileFromArrayBuffer, exportCSV, removeEmptyProps } from 'src/utils/common';
import {
  activateOffer,
  createOffer,
  createOfferDetails,
  deleteOfferById,
  downloadOfferDocuments,
  downloadOfferDocumentsForQuestionnaire,
  duplicateOfferById,
  exportOfferList,
  getAllClient,
  getAllFundByClient,
  getAllUnitClassByFund,
  getDeclarationOffer,
  getDuplicateOfferById,
  getFundBrandingForOffer,
  getFundBrandingOfferById,
  getInvestmentEntityTypes,
  getInvitationHistories,
  getInvitationWithEntity,
  getOfferById,
  getOfferClientBranding,
  getOfferFilter,
  getOfferList,
  getOfferPreviewByUnitClass,
  getOfferUnitClasses,
  publishWithEntity,
  sentEmailInvitation,
  suspendOffer,
  updateOfferDetails,
  upsertOfferDocuments,
  upsertOfferExecutionDeclarations,
  upsertOfferInvestmentDetails,
  upsertOfferQuestionnaire,
} from './services';
import {
  IDocument,
  IEmailInvitation,
  IOfferById,
  IOfferClientBranding,
  IOfferDetail,
  IOfferDocument,
  IOfferExecutionDeclaration,
  IOfferInvestment,
  IOfferInvestmentEntityTypes,
  IOfferPreview,
  IOfferUnitClass,
  IPublishWithEntity,
  IUpsertOfferQuestionnaireBody,
  Offer,
  OfferFilter,
  OfferQueryBody,
} from './type';

export const useGetOfferList = () => {
  const [queryBody, setQueryBody] = useState<OfferQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<Paginated<Offer>>(
    [OFFER_API.getOfferList.api, queryBody],
    () => getOfferList(queryBody),
  );

  const setParams = (newParams: OfferQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetOfferFilters = () => {
  const { data = { statuses: [] }, ...rest } = useQuery<OfferFilter>(
    [OFFER_API.getOfferFilter.api],
    getOfferFilter,
  );

  return {
    data,
    ...rest,
  };
};

export const useExportOfferList = () => {
  const resp = async (params: OfferQueryBody) => {
    const data = await exportOfferList(params);
    exportCSV(data, `Offer_List_${dayjs().format('DDMMYYYY')}`);
  };
  return useMutation(resp);
};

export const useDeleteOfferById = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await deleteOfferById(id);
    await queryClient.invalidateQueries([OFFER_API.getOfferList.api]);
  };
  return useMutation(resp);
};

export const useGetOfferById = (id = '', step?: number) => {
  return useQuery<IOfferById>(
    [OFFER_API.offerById.api, id, step],
    () => getOfferById(id, String(step)),
    {
      enabled: !!id && Number.isInteger(step),
    },
  );
};

export const useUpsertOfferDetails = () => {
  const queryClient = useQueryClient();
  const resp = async ({ data, id }: { data: IOfferDetail; id: string }) => {
    let res;
    if (id) {
      res = await updateOfferDetails(data, id);
    } else {
      res = await createOfferDetails(data);
    }
    queryClient.invalidateQueries([OFFER_API.offerById.api]);
    return res;
  };
  return useMutation(resp);
};

export const useCreateOffer = () => {
  return useMutation(createOffer);
};

export const useGetInvestmentEntityTypes = () => {
  return useQuery<IOfferInvestmentEntityTypes[]>(
    [OFFER_API.getInvestmentEntityTypes.api],
    getInvestmentEntityTypes,
  );
};

export const useUpsertOfferInvestmentDetails = () => {
  const queryClient = useQueryClient();

  const resp = async ({ params, id }: { params: IOfferInvestment; id: string }) => {
    await upsertOfferInvestmentDetails(params, id);
    await queryClient.invalidateQueries([OFFER_API.offerById.api]);
  };
  return useMutation(resp);
};

export const useGetOfferUnitClasses = (offerId: string) => {
  return useQuery<IOfferUnitClass[]>(
    [OFFER_API.getOfferUnitClasses.api(offerId), offerId],
    () => getOfferUnitClasses(offerId),
    { enabled: !!offerId },
  );
};

export const useGetOfferPreviewByUnitClass = (offerId: string, unitClassId: string) => {
  return useQuery<IOfferPreview>(
    [OFFER_API.getOfferPreviewByUnitClass.api(offerId, unitClassId), offerId, unitClassId],
    () => getOfferPreviewByUnitClass(offerId, unitClassId),
    {
      enabled: !!offerId && !!unitClassId,
    },
  );
};

export const useGetOfferClientBranding = (clientId: string) => {
  return useQuery<IOfferClientBranding>(
    [OFFER_API.getOfferClientBranding.api, clientId],
    () => getOfferClientBranding(clientId),
    {
      enabled: !!clientId,
    },
  );
};

export const useGetAllClient = () => {
  return useQuery<IOverarchingFilter['clients']>([OFFER_API.getAllClient.api], () =>
    getAllClient(),
  );
};

export const useGetAllFundByClient = (id = '') => {
  return useQuery<IOverarchingFilter['funds']>(
    [OFFER_API.getAllFundByClient.api(id)],
    () => getAllFundByClient(id),
    {
      enabled: !!id,
    },
  );
};

export const useGetAllUnitClassByFund = (id = '') => {
  return useQuery<IUnitClass[]>(
    [OFFER_API.getAllUnitClassByFund.api(id)],
    () => getAllUnitClassByFund(id),
    {
      enabled: !!id,
    },
  );
};

export const useGetOfferFundBrandingById = (id = '') => {
  return useQuery([OFFER_API.fundBrandingOfferDetails.api, id], () => getFundBrandingOfferById(id));
};

export const useGetDeclarationOffer = () => {
  return useQuery([OFFER_API.getDeclarationOffer.api], () => getDeclarationOffer());
};

export const useUpsertOfferExecutionDeclarations = () => {
  const queryClient = useQueryClient();

  const resp = async ({ data, id }: { data: IOfferExecutionDeclaration; id: string }) => {
    await upsertOfferExecutionDeclarations(data, id);
    await queryClient.invalidateQueries([OFFER_API.offerById.api]);
  };
  return useMutation(resp);
};
export const useSuspendOffer = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await suspendOffer(id);
    await queryClient.invalidateQueries([OFFER_API.getOfferList.api]);
  };
  return useMutation(resp);
};

export const useActivateOffer = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await activateOffer(id);
    await queryClient.invalidateQueries([OFFER_API.getOfferList.api]);
  };
  return useMutation(resp);
};

export const useUpsertOfferDocuments = () => {
  const queryClient = useQueryClient();

  const resp = async ({ data, id }: { data: IOfferDocument; id: string }) => {
    await upsertOfferDocuments(data, id);
    await queryClient.invalidateQueries([OFFER_API.offerById.api]);
  };
  return useMutation(resp);
};

export const useDownloadOfferDocument = () => {
  const resp = async ({ document, offerId }: { document: IDocument; offerId: string }) => {
    const data = await downloadOfferDocuments(offerId, document.id || '');
    downloadFileFromArrayBuffer(data, document.fileName);
  };
  return useMutation(resp);
};

export const useGetFundBrandingForOffer = (id = '') => {
  return useQuery<IOfferDocument>(
    [OFFER_API.getFundBrandingForOffer.api(id)],
    () => getFundBrandingForOffer(id),
    {
      enabled: !!id,
    },
  );
};

export const useSentEmailInvitation = () => {
  const resp = async ({ data, id }: { data: IEmailInvitation; id: string }) => {
    await sentEmailInvitation(data, id);
  };
  return useMutation(resp);
};

export const usePublishWithEntity = () => {
  const resp = async ({
    data,
    id,
    isSentMail,
  }: {
    data: IPublishWithEntity;
    id: string;
    isSentMail: boolean;
  }) => {
    await publishWithEntity(data, id, isSentMail);
  };
  return useMutation(resp);
};

export const useGetInvitationWithEntity = (id: string) => {
  return useQuery([OFFER_API.invitationWithEntity.api(id), id], () => getInvitationWithEntity(id), {
    enabled: !!id,
  });
};

export const useGetInvitationHistories = (id: string) => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data, refetch, ...rest } = useQuery(
    [OFFER_API.invitationHistories.api(id)],
    () => getInvitationHistories(queryBody, id),
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    refetch();
  }, [queryBody]);

  const setParams = (newParams: BaseQuery) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  const result = {
    items: data?.items,
    metadata: data?.metadata,
  };

  return {
    data: result,
    refetch,
    setParams,
    ...rest,
  };
};
export const useGetDuplicateOfferById = () => {
  return useMutation(getDuplicateOfferById);
};

export const useDuplicateOfferById = (id: string) => {
  const resp = async (params: IOfferById) => {
    return await duplicateOfferById(id, params);
  };
  return useMutation(resp);
};

export const useUpsertOfferQuestionnaire = () => {
  const queryClient = useQueryClient();

  const resp = async (data: { offerId: string; body: IUpsertOfferQuestionnaireBody }) => {
    await upsertOfferQuestionnaire(data);
  };
  return useMutation(resp);
};

export const useDownloadOfferDocumentsForQuestionnaire = () => {
  const resp = async (data: {
    offerId: string;
    offerDocumentType: number;
    unitClassId: string;
  }) => {
    const res = await downloadOfferDocumentsForQuestionnaire(data);
    downloadFileFromArrayBuffer(res.data, res.filename);
  };
  return useMutation(resp);
};
