import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import BracketPrimaryIcon from 'src/assets/icons/BracketPrimaryIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { detectPortalType } from 'src/helpers/common';
import { useEnterKeyPress } from 'src/modules/common/hooks';

interface IFormSectionProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  isLastStep?: boolean;
  isLoading?: boolean;
  onSubmit?: () => void;
  onSave?: () => void;
  isEditMode?: boolean;
  isViewMode?: boolean;
  isDraft?: boolean;
  description?: ReactNode;
  fullWidth?: boolean;
  createBtnText?: string;
  customHeader?: ReactNode;
  withoutAction?: boolean;
  disabled?: boolean;
  hiddenSaveBtn?: boolean;
  hiddenSubmitBtn?: boolean;
  customActionButton?: ReactNode;
}

const FormSection: FC<IFormSectionProps> = ({
  children,
  title,
  isEditMode,
  isLastStep,
  onSave,
  onSubmit,
  isViewMode,
  isLoading,
  description,
  fullWidth = false,
  createBtnText,
  isDraft,
  customHeader,
  withoutAction = false,
  disabled = false,
  hiddenSaveBtn = false,
  hiddenSubmitBtn = false,
  customActionButton,
}) => {
  onSubmit && useEnterKeyPress(onSubmit);
  const { isFunds, isInvestor } = detectPortalType();

  const renderSubmitButton = () => {
    if (isViewMode && isLastStep) return <></>;
    let buttonLabel = '';
    const createButtonLabel = isLastStep ? createBtnText || 'Create New Client' : 'Next';

    if (isViewMode) {
      buttonLabel = 'Next';
    } else if (isDraft) {
      buttonLabel = createButtonLabel;
    } else {
      buttonLabel = isEditMode ? 'Save' : createButtonLabel;
    }

    return (
      <CustomButton onClick={onSubmit} isLoading={isLoading} disabled={disabled} className='ml-6'>
        {buttonLabel}
      </CustomButton>
    );
  };

  const renderSaveButton = () => {
    if (isViewMode) return <></>;

    // Create mode or draft funds client we show Save else show Next
    const buttonLabel = !isEditMode || isDraft ? 'Save' : 'Next';

    return (
      <CustomButton
        variant='outlined'
        sx={(theme) => ({
          color: theme.palette.neutral.ne800,
          borderColor: theme.palette.neutral.ne800,
        })}
        onClick={onSave}
        disabled={isLoading || disabled}
      >
        {buttonLabel}
      </CustomButton>
    );
  };

  return (
    <Box className='flex flex-col'>
      <Box className='flex-1 pb-15'>
        {!!customHeader && customHeader}
        <Box className='flex flex-col items-center'>
          {title && (
            <Box className='flex justify-center items-center'>
              <BracketPrimaryIcon />
              <Typography variant='h6' align='center' sx={{ pl: 1.5 }}>
                {title}
              </Typography>
            </Box>
          )}
          {description && (
            <Typography variant='body2' color='neutral.ne800' paddingTop={1}>
              {description}
            </Typography>
          )}
        </Box>
        <Box className='pt-6 flex flex-col items-center px-[90px]'>
          <Box
            className={clsx({
              'min-w-[728px]': true,
              'w-full': fullWidth,
              'w-5/12': !fullWidth,
            })}
          >
            {children}
          </Box>
        </Box>
      </Box>
      {!withoutAction && (
        <Box className='flex justify-end py-3.5 px-7.5 border-t' borderColor='neutral.ne200'>
          {!!customActionButton && customActionButton}
          {!hiddenSaveBtn && renderSaveButton()}
          {!hiddenSubmitBtn && renderSubmitButton()}
        </Box>
      )}
    </Box>
  );
};

export default FormSection;
