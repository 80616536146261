import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';

import { Dialog, DialogProps, DialogTitle, styled } from '@mui/material';

export interface IBasicModalElement {
  open: () => void;
  close: () => void;
}

type IBasicModalProps = {
  title?: ReactNode;
  children: ReactNode;
  disableClose?: boolean;
  radius?: string;
  sx?: any;
  isPreventClickOutside?: boolean;
} & Omit<DialogProps, 'open' | 'title'>;

const CustomDialog = styled(Dialog)<{ radius: string }>(({ radius, theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: radius,
    /* Works on firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.neutral.ne300,

    /* Works on Chrome, Edge, and Safari */
    '&::-webkit-scrollbar': {
      width: '4px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutral.ne300,
      borderRadius: 3,
    },

    '&::-webkit-scrollbar-track': {
      marginTop: '300px',
      marginBottom: '300px',
    },
  },
}));

export const BasicModal = forwardRef<IBasicModalElement, IBasicModalProps>(
  (
    {
      children,
      title,
      disableClose = false,
      radius = '22px',
      sx = {},
      onClose,
      isPreventClickOutside,
      ...rest
    },
    ref,
  ): JSX.Element => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        open() {
          setOpen(true);
          document.body.style.overflow = 'hidden';
        },
        close() {
          setOpen(false);
          document.body.style.overflow = '';
        },
      }),
      [],
    );

    const handleClose = (
      event: React.MouseEvent<HTMLDivElement>,
      reason: 'backdropClick' | 'backdropClick',
    ) => {
      if (isPreventClickOutside && reason && reason == 'backdropClick') return;

      if (typeof onClose === 'function') return onClose(event, reason);

      if (disableClose) return;

      if (event.currentTarget !== event.target) {
        setOpen(false);
      }
    };

    return (
      <CustomDialog
        {...rest}
        open={open}
        onClose={handleClose}
        radius={radius}
        sx={{ boxShadow: 'none', ...sx }}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {children}
      </CustomDialog>
    );
  },
);
